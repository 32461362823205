
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WidgetType } from '../../types/widget';
import { useTheme } from '../../contexts/ThemeContext';
import { Alert } from '../ui/alert';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { useWidgetGenerator } from '../../hooks/useWidgetGenerator';
import { PromptSuggestions } from './PromptSuggestions';
import { DynamicWidgetRenderer } from './DynamicWidgetRenderer';
import {
  Wand2,
  Save,
  RefreshCw,
  AlertTriangle,
  Code,
  Eye
} from 'lucide-react';

interface WidgetGeneratorProps {
    initialType?: WidgetType;
  }

  export const WidgetGenerator: React.FC<WidgetGeneratorProps> = ({ initialType }) => {
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();
    const {
      generateWidget,
      clearPreview,
      previewCode,
      isGenerating,
      error,
      setError
    } = useWidgetGenerator();
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [showPreview, setShowPreview] = useState(true);
    const [selectedType, setSelectedType] = useState<WidgetType>(initialType || WidgetType.CHART);
  

    
    useEffect(() => {
        if (initialType) {
          setSelectedType(initialType);
        }
      }, [initialType]);
      
    const handlePromptSubmit = async () => {
        if (!prompt.trim()) return;
        
        const widget = await generateWidget({
          name: name.trim() || 'Untitled Widget',
          prompt: prompt.trim()
        });
    
        if (widget) {
          // Preview is automatically set by the hook
        }
      };

      const handleSave = async () => {
        if (!previewCode || !prompt.trim()) return;
        
        try {
          const widget = await generateWidget({
            name: name.trim() || 'Untitled Widget',
            prompt: prompt.trim()
          });
    
          if (widget) {
            navigate('/dashboard/library');
          }
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to save widget');
        }
      };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Left Panel - Generator */}
        <div className="w-full lg:w-1/2 space-y-6">
          <div className={`p-6 rounded-xl ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          } shadow-lg`}>
            <div className="mb-6">
              <label className={`block text-sm font-medium ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              } mb-2`}>
                Widget Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="My Widget"
                className={`w-full px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-700 text-white border-gray-600'
                    : 'bg-white text-gray-900 border-gray-300'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              />
            </div>

            <div className="mb-6">
              <label className={`block text-sm font-medium ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              } mb-2`}>
                Describe Your Widget
              </label>
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                rows={6}
                placeholder="Describe what kind of widget you want to create..."
                className={`w-full px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-700 text-white border-gray-600'
                    : 'bg-white text-gray-900 border-gray-300'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              />
            </div>

            {error && (
              <Alert variant="destructive" className="mb-6">
                <AlertTriangle className="h-4 w-4" />
                <span>{error}</span>
              </Alert>
            )}

            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowPreview(!showPreview)}
                className={`px-4 py-2 rounded-lg flex items-center ${
                  isDarkMode
                    ? 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                    : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                }`}
              >
                {showPreview ? (
                  <>
                    <Code className="w-4 h-4 mr-2" />
                    Show Code
                  </>
                ) : (
                  <>
                    <Eye className="w-4 h-4 mr-2" />
                    Show Preview
                  </>
                )}
              </button>
              <button
                onClick={handlePromptSubmit}
                disabled={!prompt.trim() || isGenerating}
                className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 flex items-center"
              >
                {isGenerating ? (
                  <>
                    <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
                    Generating...
                  </>
                ) : (
                  <>
                    <Wand2 className="w-4 h-4 mr-2" />
                    Generate
                  </>
                )}
              </button>
              {previewCode && (
                <button
                  onClick={handleSave}
                  className="px-4 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 flex items-center"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save Widget
                </button>
              )}
            </div>
          </div>

          <PromptSuggestions onSelect={setPrompt} />
        </div>

        {/* Right Panel - Preview */}
        <div className="w-full lg:w-1/2">
          <div className={`p-6 rounded-xl ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          } shadow-lg`}>
            <h3 className={`text-lg font-medium mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {showPreview ? 'Widget Preview' : 'Generated Code'}
            </h3>
            
            {isGenerating ? (
              <div className="flex items-center justify-center h-64">
                <LoadingSpinner size="lg" />
              </div>
            ) : previewCode ? (
              showPreview ? (
                <div className="h-[500px] overflow-auto rounded-lg">
                  <DynamicWidgetRenderer code={previewCode} />
                </div>
              ) : (
                <pre className={`p-4 rounded-lg overflow-auto ${
                  isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                }`}>
                  <code className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
                    {previewCode}
                  </code>
                </pre>
              )
            ) : (
              <div className={`flex flex-col items-center justify-center h-64 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                <Wand2 className="w-12 h-12 mb-4 opacity-50" />
                <p>Enter a description and generate your widget</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetGenerator;
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { CreditDisplay } from '../../components/common/CreditDisplay';
import {
  LayoutDashboard,
  LineChart,
  Library,
  Settings,
  Plus,
  LogOut,
  Moon,
  Sun,
  Search,
  Bell,
  ChevronRight,
  Zap,
  GitBranch,
  History,
  Monitor
} from 'lucide-react';

interface NavItem {
  icon: React.FC<{ className?: string }>;
  label: string;
  path: string;
  badge?: number;
}

export const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const { logout, user } = useAuth();
  const [notifications] = useState<number>(3); // Example notification count
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const primaryNavItems: NavItem[] = [
    { icon: LayoutDashboard, label: 'Overview', path: '/dashboard' },
    { icon: LineChart, label: 'Market Data', path: '/dashboard/market' },
    { icon: Library, label: 'Widget Library', path: '/dashboard/library', badge: 5 },
    { icon: Monitor, label: 'Monitors', path: '/dashboard/monitors' },
  ];

  const secondaryNavItems: NavItem[] = [
    { icon: Settings, label: 'Settings', path: '/dashboard/settings' },
    { icon: History, label: 'History', path: '/dashboard/history' },
    { icon: GitBranch, label: 'Versions', path: '/dashboard/versions' },
  ];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className={`flex h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      {/* Sidebar */}
      <div className={`w-64 flex-shrink-0 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-r ${
        isDarkMode ? 'border-gray-700' : 'border-gray-200'
      }`}>
        <div className="flex flex-col h-full">
          {/* Logo Section */}
          <div className="p-6 border-b border-gray-700">
            <img
              src={isDarkMode ? '/logo-dark.png' : '/logo-light.png'}
              alt="Newsroom"
              className="h-8"
            />
          </div>

          {/* Main Navigation */}
          <div className="flex-1 overflow-y-auto px-4 py-6">
            {/* Create Widget Button */}
            <button
              onClick={() => navigate('/dashboard/create')}
              className="w-full mb-6 px-4 py-3 flex items-center justify-center space-x-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
            >
              <Plus className="w-5 h-5" />
              <span>Create Widget</span>
            </button>

            {/* Primary Nav Items */}
            <div className="space-y-1">
              {primaryNavItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center px-4 py-3 rounded-lg transition-colors ${
                    location.pathname === item.path
                      ? `${isDarkMode ? 'bg-gray-700' : 'bg-blue-50'} text-blue-600`
                      : `${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'}`
                  }`}
                >
                  <item.icon className="w-5 h-5 mr-3" />
                  <span>{item.label}</span>
                  {item.badge && (
                    <span className="ml-auto bg-blue-600 text-white text-xs px-2 py-1 rounded-full">
                      {item.badge}
                    </span>
                  )}
                </Link>
              ))}
            </div>

            {/* Secondary Nav */}
            <div className="mt-8">
              <div className="px-4 mb-2 text-xs font-semibold text-gray-500 uppercase">
                Settings & Tools
              </div>
              <div className="space-y-1">
                {secondaryNavItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`flex items-center px-4 py-3 rounded-lg transition-colors ${
                      location.pathname === item.path
                        ? `${isDarkMode ? 'bg-gray-700' : 'bg-blue-50'} text-blue-600`
                        : `${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'}`
                    }`}
                  >
                    <item.icon className="w-5 h-5 mr-3" />
                    <span>{item.label}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          {/* User Section */}
          <div className={`p-4 border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
            <div className="flex items-center mb-4">
              <div className={`w-10 h-10 rounded-full ${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
              } flex items-center justify-center`}>
                <span className={isDarkMode ? 'text-white' : 'text-gray-700'}>
                  {user?.name?.[0]?.toUpperCase()}
                </span>
              </div>
              <div className="ml-3">
                <p className={`text-sm font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {user?.name}
                </p>
                <div className="flex items-center space-x-2">
                  <CreditDisplay showBuyButton={false} />
                  <Zap className="w-4 h-4 text-yellow-500" />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <button
                onClick={toggleDarkMode}
                className={`p-2 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-700 text-gray-300' 
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
              </button>
              <button
                onClick={handleLogout}
                className="flex items-center px-3 py-2 text-sm text-red-500 hover:text-red-600"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col min-w-0">
        {/* Top Bar */}
        <div className={`h-16 flex-shrink-0 flex items-center justify-between px-6 ${
          isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        } border-b`}>
          <div className="flex items-center space-x-4">
            {/* Search */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className={`w-64 px-4 py-2 pl-10 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white placeholder-gray-400' 
                    : 'bg-gray-100 text-gray-900 placeholder-gray-500'
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              <Search className={`absolute left-3 top-2.5 w-4 h-4 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            {/* Notifications */}
            <button className="relative p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <Bell className="w-5 h-5" />
              {notifications > 0 && (
                <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full" />
              )}
            </button>

            {/* Quick Actions */}
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center">
              <Plus className="w-4 h-4 mr-2" />
              Quick Trade
            </button>
          </div>
        </div>

        {/* Content Area */}
        <div className="flex-1 overflow-auto">
          <div className="p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
// src/pages/legal/privacy.tsx
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

import { Shield, ChevronRight } from 'lucide-react';

const sections = [
  {
    title: 'Information We Collect',
    content: `We collect information you provide directly to us, including:
    • Account information (name, email, password)
    • Trading preferences and widget configurations
    • Usage data and interaction with our services
    • Technical data such as IP address and device information`
  },
  {
    title: 'How We Use Your Information',
    content: `We use the collected information to:
    • Provide and maintain our services
    • Personalize your experience
    • Improve our platform
    • Communicate with you about updates and features
    • Ensure platform security and prevent fraud`
  },
  {
    title: 'Data Storage and Security',
    content: `We implement industry-standard security measures to protect your data:
    • End-to-end encryption for sensitive data
    • Regular security audits and monitoring
    • Secure data centers with redundancy
    • Access controls and authentication
    • Regular backups and disaster recovery plans`
  },
  {
    title: 'Your Rights and Choices',
    content: `You have the right to:
    • Access your personal data
    • Request data correction or deletion
    • Object to data processing
    • Export your data
    • Withdraw consent at any time`
  },
  {
    title: 'Third-Party Services',
    content: `We may share data with trusted third parties for:
    • Payment processing
    • Analytics
    • Customer support
    • Marketing (with your consent)
    All third parties must adhere to our privacy standards.`
  },
  {
    title: 'Updates to Privacy Policy',
    content: `We may update this privacy policy periodically. We will notify you of any material changes via email or through our platform. Continued use of our services after changes constitutes acceptance of the updated policy.`
  }
];

const PrivacyPolicy = () => {
  const { isDarkMode } = useTheme();

  return (
   
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Shield className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Privacy Policy
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Last updated: March 15, 2024
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`mb-12 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            <p className="mb-4">
              At Newsroom, we take your privacy seriously. This Privacy Policy describes how we collect,
              use, and handle your personal information when you use our platform and services.
            </p>
            <p>
              By using Newsroom, you agree to the collection and use of information in accordance
              with this policy.
            </p>
          </div>

          {/* Quick Navigation */}
          <div className={`mb-12 p-6 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h2 className={`text-lg font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Quick Navigation
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              {sections.map((section, index) => (
                <a
                  key={index}
                  href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className={`flex items-center justify-between p-3 rounded-lg transition-colors ${
                    isDarkMode
                      ? 'hover:bg-gray-700 text-gray-300'
                      : 'hover:bg-gray-50 text-gray-600'
                  }`}
                >
                  <span>{section.title}</span>
                  <ChevronRight className="w-4 h-4" />
                </a>
              ))}
            </div>
          </div>

          {/* Sections */}
          <div className="space-y-12">
            {sections.map((section, index) => (
              <section
                key={index}
                id={section.title.toLowerCase().replace(/\s+/g, '-')}
                className={`scroll-mt-24 ${
                  index !== sections.length - 1 ? 'pb-12 border-b' : ''
                } ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
              >
                <h2 className={`text-2xl font-bold mb-6 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {section.title}
                </h2>
                <div className={`prose max-w-none ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600' }`}>
                  {section.content.split('\n').map((paragraph, i) => (
                    <p key={i} className="whitespace-pre-line mb-4">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </section>
            ))}
          </div>

          {/* Contact Section */}
          <div className={`mt-16 p-6 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h2 className={`text-lg font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Questions About Our Privacy Policy?
            </h2>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <a
              href="mailto:privacy@news-room.ca"
              className="text-blue-500 hover:text-blue-600"
            >
              privacy@news-room.ca
            </a>
          </div>
        </div>
      </div>
    
  );
};

export default PrivacyPolicy;
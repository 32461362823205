// src/components/widgets/WidgetCard.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { Widget } from '../../types/widget';
import { DynamicWidgetRenderer } from './DynamicWidgetRenderer';
import {
  MoreVertical,
  Edit,
  Copy,
  Trash2,
  Download,
  Share2,
  Code,
  Maximize2
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

interface WidgetCardProps {
  widget: Widget;
  onDelete: () => Promise<void>;
  viewMode?: 'grid' | 'list';
}

export const WidgetCard: React.FC<WidgetCardProps> = ({
  widget,
  onDelete,
  viewMode = 'grid'
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleEdit = () => {
    navigate(`/dashboard/customize/${widget.id}`);
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this widget?')) {
      setIsDeleting(true);
      try {
        await onDelete();
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleDuplicate = () => {
    // Implement duplication logic
  };

  const handleExport = () => {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(widget, null, 2)
    )}`;
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `widget-${widget.id}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const handleShare = () => {
    // Implement sharing logic
  };

  const CardContent = () => (
    <>
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className={`font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            {widget.name}
          </h3>
          <p className={`text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {widget.description || 'No description'}
          </p>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className={`p-2 rounded-lg ${
              isDarkMode 
                ? 'hover:bg-gray-700' 
                : 'hover:bg-gray-100'
            }`}>
              <MoreVertical className="w-5 h-5" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={handleEdit}>
              <Edit className="w-4 h-4 mr-2" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setShowCode(!showCode)}>
              <Code className="w-4 h-4 mr-2" />
              {showCode ? 'Hide Code' : 'View Code'}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDuplicate}>
              <Copy className="w-4 h-4 mr-2" />
              Duplicate
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleExport}>
              <Download className="w-4 h-4 mr-2" />
              Export
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleShare}>
              <Share2 className="w-4 h-4 mr-2" />
              Share
            </DropdownMenuItem>
            <DropdownMenuItem 
              onClick={handleDelete}
              className="text-red-600 hover:text-red-700 focus:text-red-700"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              {isDeleting ? 'Deleting...' : 'Delete'}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {/* Preview/Code */}
      <div className={`relative rounded-lg overflow-hidden ${
        viewMode === 'grid' ? 'h-[300px]' : 'h-[200px]'
      }`}>
        {showCode ? (
          <pre className={`h-full p-4 overflow-auto ${
            isDarkMode ? 'bg-gray-900' : 'bg-gray-50'
          }`}>
            <code className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
              {widget.code || 'No code available'}
            </code>
          </pre>
        ) : (
          <DynamicWidgetRenderer
            code={widget.code || ''}
            className="h-full"
          />
        )}
      </div>

      {/* Footer */}
      <div className={`mt-4 flex items-center justify-between text-sm ${
        isDarkMode ? 'text-gray-400' : 'text-gray-600'
      }`}>
        <span className={`px-2 py-1 rounded ${
          isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
        }`}>
          {widget.type}
        </span>
        <span>
          Updated {new Date(widget.updatedAt).toLocaleDateString()}
        </span>
      </div>
    </>
  );

  return viewMode === 'grid' ? (
    <div className={`rounded-xl ${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    } shadow-lg p-6`}>
      <CardContent />
    </div>
  ) : (
    <div className={`flex gap-6 rounded-xl ${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    } shadow-lg p-6`}>
      <div className="flex-1">
        <CardContent />
      </div>
    </div>
  );
};

export default WidgetCard;
import React, { useEffect, useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Monitor, X } from 'lucide-react';

interface MobileWarningProps {
  onClose: () => void;
}

const isMobile = () => {
  return window.innerWidth < 768;
};

const MobileWarning: React.FC<MobileWarningProps> = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isMobile()) {
      setShow(true);
    }

    const handleResize = () => {
      setShow(isMobile());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div className="absolute inset-0 bg-black/80 backdrop-blur-sm" />
      
      {/* Modal */}
      <div className={`relative w-full max-w-sm rounded-2xl ${
        isDarkMode ? 'bg-gray-900' : 'bg-white'
      } shadow-2xl`}>
        {/* Close Button */}
        <button 
          onClick={onClose}
          className={`absolute right-4 top-4 p-2 rounded-full transition-colors ${
            isDarkMode 
              ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
              : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
          }`}
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-8">
          {/* Icon */}
          <div className={`w-12 h-12 rounded-full mb-6 flex items-center justify-center ${
            isDarkMode ? 'bg-blue-900/50' : 'bg-blue-100'
          }`}>
            <Monitor className="w-6 h-6 text-blue-500" />
          </div>
          
          {/* Content */}
          <h2 className={`text-xl font-bold mb-3 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Desktop Experience Recommended
          </h2>
          
          <p className={`mb-8 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Newsroom is optimized for desktop use. For the best experience creating and managing widgets, please visit us on a desktop or laptop computer.
          </p>
          
          {/* Buttons */}
          <div className="flex flex-col space-y-3">
            <a
              href="mailto:?subject=Check%20out%20Newsroom&body=Visit%20Newsroom%20on%20desktop%20to%20create%20AI-powered%20trading%20widgets:%20"
              className="w-full px-4 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 text-center font-medium transition-colors"
            >
              Email Link
            </a>
            
            <button
              onClick={onClose}
              className={`w-full px-4 py-3 rounded-lg font-medium transition-colors ${
                isDarkMode
                  ? 'bg-gray-800 text-gray-200 hover:bg-gray-700'
                  : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              }`}
            >
              Continue Anyway
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileWarning;
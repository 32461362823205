import { useState } from 'react';
import { useWidget } from './useWidget';
import { WidgetType } from '../types/widget';

export const useWidgetGenerator = () => {
  const { createWidget } = useWidget();
  const [isGenerating, setIsGenerating] = useState(false);
  const [previewCode, setPreviewCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const generateWidget = async (params: {
    name: string;
    prompt: string;
    type?: WidgetType;
  }) => {
    setIsGenerating(true);
    setError(null);
    try {
      const widget = await createWidget({
        name: params.name,
        type: params.type || WidgetType.CHART,
        prompt: params.prompt,
        description: params.prompt
      });
      
      setPreviewCode(widget.code || null);
      return widget;
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to generate widget';
      setError(message);
      throw new Error(message);
    } finally {
      setIsGenerating(false);
    }
  };

  return {
    generateWidget,
    previewCode,
    isGenerating,
    error,
    setError,
    clearPreview: () => setPreviewCode(null)
  };
};

export default useWidgetGenerator;
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { siteConfig } from '../../config/seo';

interface SEOProps {
  title?: string;
  description?: string;
  canonical?: string;
  ogImage?: string;
  noindex?: boolean;
  articleMeta?: {
    publishedTime: string;
    modifiedTime: string;
    authors: string[];
    tags: string[];
  };
  jsonLd?: Record<string, any>;
}

export const SEO: React.FC<SEOProps> = ({
  title,
  description = siteConfig.description,
  canonical = siteConfig.siteUrl,
  ogImage = `${siteConfig.siteUrl}/og-image.png`,
  noindex = false,
  articleMeta,
  jsonLd,
}) => {
  const fullTitle = title 
    ? `${title} | ${siteConfig.title}`
    : siteConfig.title;

  const defaultJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": siteConfig.title,
    "description": description,
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web",
    "author": {
      "@type": "Organization",
      "name": siteConfig.organization.name,
      "url": siteConfig.organization.url,
      "logo": siteConfig.organization.logo
    },
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <html lang={siteConfig.language} />
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />
      {noindex && <meta name="robots" content="noindex,nofollow" />}

      {/* Open Graph */}
      <meta property="og:site_name" content={siteConfig.title} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content={articleMeta ? 'article' : 'website'} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteConfig.twitterHandle} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />

      {/* Article Meta */}
      {articleMeta && (
        <>
          <meta property="article:published_time" content={articleMeta.publishedTime} />
          <meta property="article:modified_time" content={articleMeta.modifiedTime} />
          {articleMeta.authors.map(author => (
            <meta key={author} property="article:author" content={author} />
          ))}
          {articleMeta.tags.map(tag => (
            <meta key={tag} property="article:tag" content={tag} />
          ))}
        </>
      )}

      {/* JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLd || defaultJsonLd)}
      </script>
    </Helmet>
  );
};
// src/components/widgets/WidgetLibrary.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { useTheme } from '../../contexts/ThemeContext';
import { WidgetCard } from './WidgetCard';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import {
  Plus,
  Search,
  Filter,
  Grid,
  List,
  SortAsc,
  Sparkles
} from 'lucide-react';

type ViewMode = 'grid' | 'list';
type SortOption = 'newest' | 'oldest' | 'name' | 'type';

export const WidgetLibrary: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { widgets, isLoading, error, deleteWidget, refetch } = useWidget();
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<SortOption>('newest');
  const [selectedType, setSelectedType] = useState<string>('all');

  // Filter and sort widgets
  const filteredWidgets = widgets
    .filter(widget => {
      const matchesSearch = widget.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          widget.description?.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesType = selectedType === 'all' || widget.type === selectedType;
      return matchesSearch && matchesType;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'newest':
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        case 'oldest':
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        case 'name':
          return a.name.localeCompare(b.name);
        case 'type':
          return a.type.localeCompare(b.type);
        default:
          return 0;
      }
    });

  const handleDelete = async (widgetId: string) => {
    try {
      await deleteWidget(widgetId);
      await refetch();
    } catch (error) {
      console.error('Failed to delete widget:', error);
    }
  };

  if (isLoading) {
    return <LoadingSpinner size="lg" />;
  }

  if (error) {
    return <Alert variant="destructive">{error}</Alert>;
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <h1 className={`text-2xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Widget Library
        </h1>
        
        <button
          onClick={() => navigate('/dashboard/create')}
          className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Create Widget
        </button>
      </div>

      {/* Filters and Controls */}
      <div className="flex flex-col sm:flex-row gap-4">
        {/* Search */}
        <div className="relative flex-grow">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search widgets..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={`w-full pl-10 pr-4 py-2 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-200'
            }`}
          />
        </div>

        {/* View Mode Toggle */}
        <div className={`inline-flex rounded-lg ${
          isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
        }`}>
          <button
            onClick={() => setViewMode('grid')}
            className={`p-2 ${viewMode === 'grid' 
              ? 'bg-blue-600 text-white' 
              : isDarkMode ? 'text-gray-400' : 'text-gray-600'
            } rounded-l-lg`}
          >
            <Grid className="w-5 h-5" />
          </button>
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 ${viewMode === 'list' 
              ? 'bg-blue-600 text-white' 
              : isDarkMode ? 'text-gray-400' : 'text-gray-600'
            } rounded-r-lg`}
          >
            <List className="w-5 h-5" />
          </button>
        </div>

        {/* Sort Dropdown */}
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as SortOption)}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-gray-800 text-white border-gray-700' 
              : 'bg-white text-gray-900 border-gray-200'
          }`}
        >
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
          <option value="name">Name</option>
          <option value="type">Type</option>
        </select>

        {/* Type Filter */}
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className={`px-4 py-2 rounded-lg ${
            isDarkMode 
              ? 'bg-gray-800 text-white border-gray-700' 
              : 'bg-white text-gray-900 border-gray-200'
          }`}
        >
          <option value="all">All Types</option>
          <option value="CHART">Charts</option>
          <option value="TABLE">Tables</option>
          <option value="FORM">Forms</option>
          <option value="MONITOR">Monitors</option>
        </select>
      </div>

      {/* Widget Grid/List */}
      {filteredWidgets.length === 0 ? (
        <div className="text-center py-12">
          <Sparkles className={`w-12 h-12 mx-auto mb-4 ${
            isDarkMode ? 'text-gray-600' : 'text-gray-400'
          }`} />
          <h3 className={`text-lg font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            No widgets found
          </h3>
          <p className={`mt-2 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {searchQuery 
              ? 'Try adjusting your search or filters'
              : 'Create your first widget to get started'}
          </p>
          {!searchQuery && (
            <button
              onClick={() => navigate('/dashboard/create')}
              className="mt-4 inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700"
            >
              <Plus className="w-5 h-5 mr-2" />
              Create Widget
            </button>
          )}
        </div>
      ) : (
        <div className={viewMode === 'grid' 
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-4'
        }>
          {filteredWidgets.map(widget => (
            <WidgetCard
              key={widget.id}
              widget={widget}
              onDelete={() => handleDelete(widget.id)}
              viewMode={viewMode}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default WidgetLibrary;
// client/src/pages/Pricing.tsx
import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { PricingPlans } from './pricing/PricingPlans';
import { FeatureComparison } from './pricing/FeatureComparison';
import { 
  Coins,
  CreditCard,
  Zap 
} from 'lucide-react';

const PayAsYouGo = [
  {
    credits: 100,
    price: 10,
    popular: false
  },
  {
    credits: 500,
    price: 45,
    popular: true
  },
  {
    credits: 2000,
    price: 160,
    popular: false
  }
];

const Subscriptions = [
  {
    name: 'Starter',
    price: 19,
    monthlyCredits: 200,
    features: ['Basic Widget Types', 'Standard Support', '5 Saved Widgets'],
    popular: false
  },
  {
    name: 'Pro',
    price: 49,
    monthlyCredits: 600,
    features: ['All Widget Types', 'Priority Support', '50 Saved Widgets', 'Custom Templates'],
    popular: true
  },
  {
    name: 'Team',
    price: 99,
    monthlyCredits: 1500,
    features: ['All Pro Features', 'Team Collaboration', 'Unlimited Saved Widgets', 'API Access'],
    popular: false
  }
];

export default () => {
  const { isDarkMode } = useTheme();
  const [isAnnual, setIsAnnual] = useState(true);
  const [showSubscriptions, setShowSubscriptions] = useState(true);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Pricing Header */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className={`text-4xl font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          } mb-6`}>
            Flexible Pricing for Every Trader
          </h1>
          <p className={`text-xl ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          } mb-12`}>
            Choose between pay-as-you-go credits or monthly subscriptions with automatic credit renewal.
          </p>

          {/* Plan Type Toggle */}
          <div className="flex justify-center mb-12">
            <div className={`inline-flex rounded-lg p-1 ${
              isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
            }`}>
              <button
                onClick={() => setShowSubscriptions(true)}
                className={`px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                  showSubscriptions
                    ? 'bg-blue-600 text-white'
                    : isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}
              >
                <div className="flex items-center gap-2">
                  <Zap className="w-4 h-4" />
                  Subscriptions
                </div>
              </button>
              <button
                onClick={() => setShowSubscriptions(false)}
                className={`px-6 py-2 rounded-md text-sm font-medium transition-colors ${
                  !showSubscriptions
                    ? 'bg-blue-600 text-white'
                    : isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}
              >
                <div className="flex items-center gap-2">
                  <Coins className="w-4 h-4" />
                  Pay As You Go
                </div>
              </button>
            </div>
          </div>

          {/* Billing Toggle - Only show for subscriptions */}
          {showSubscriptions && (
            <div className="flex items-center justify-center mb-12 gap-4">
              <span className={`text-sm ${
                !isAnnual 
                  ? isDarkMode ? 'text-white' : 'text-gray-900' 
                  : isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                Monthly
              </span>
              <button
                onClick={() => setIsAnnual(!isAnnual)}
                className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                  isAnnual ? 'bg-blue-600' : isDarkMode ? 'bg-gray-600' : 'bg-gray-200'
                }`}
              >
                <span className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                  isAnnual ? 'translate-x-6' : 'translate-x-1'
                }`} />
              </button>
              <span className={`text-sm ${
                isAnnual 
                  ? isDarkMode ? 'text-white' : 'text-gray-900' 
                  : isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                Annual
              </span>
              <span className="ml-2 inline-flex items-center text-sm text-green-500">
                Save 20%
              </span>
            </div>
          )}
        </div>

        {/* Pricing Plans */}
        <PricingPlans 
          isAnnual={isAnnual}
          showSubscriptions={showSubscriptions}
          subscriptions={Subscriptions}
          payAsYouGo={PayAsYouGo}
        />

        {/* Feature Comparison */}
        <FeatureComparison />
      </div>
    </div>
  );
};
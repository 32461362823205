// src/pages/auth/Register.tsx
import React from 'react';
import { MainLayout } from '../../components/layout/MainLayout';
import RegisterForm from '../../components/auth/RegisterForm';
import { useTheme } from '../../contexts/ThemeContext';


export const Register: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className="flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <RegisterForm />
      </div>
    </div>
  );
};

export default Register;
// client/src/pages/pricing/FeatureComparison.tsx
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Check, Minus } from 'lucide-react';

const features = [
  {
    category: 'Widget Types',
    features: [
      {
        name: 'Basic Charts (Line, Bar, Candlestick)',
        starter: true,
        pro: true,
        team: true
      },
      {
        name: 'Technical Indicators',
        starter: '5 Types',
        pro: '20+ Types',
        team: 'All Types'
      },
      {
        name: 'Custom Indicators',
        starter: false,
        pro: true,
        team: true
      },
      {
        name: 'Market Depth/Order Book',
        starter: false,
        pro: true,
        team: true
      },
      {
        name: 'Volume Profile',
        starter: false,
        pro: true,
        team: true
      }
    ]
  },
  {
    category: 'Data Access',
    features: [
      {
        name: 'Real-time Market Data',
        starter: '5 symbols',
        pro: '50 symbols',
        team: 'Unlimited'
      },
      {
        name: 'Historical Data',
        starter: '1 month',
        pro: '1 year',
        team: 'Full'
      },
      {
        name: 'Data Export',
        starter: false,
        pro: true,
        team: true
      },
      {
        name: 'Custom Data Integration',
        starter: false,
        pro: false,
        team: true
      }
    ]
  },
  {
    category: 'Features',
    features: [
      {
        name: 'Widget Saves',
        starter: '5',
        pro: '50',
        team: 'Unlimited'
      },
      {
        name: 'Alert System',
        starter: 'Basic',
        pro: 'Advanced',
        team: 'Advanced+'
      },
      {
        name: 'API Access',
        starter: false,
        pro: 'Limited',
        team: 'Full'
      },
      {
        name: 'Custom Templates',
        starter: false,
        pro: true,
        team: true
      },
      {
        name: 'Team Collaboration',
        starter: false,
        pro: false,
        team: true
      }
    ]
  },
  {
    category: 'Support',
    features: [
      {
        name: 'Community Support',
        starter: true,
        pro: true,
        team: true
      },
      {
        name: 'Email Support',
        starter: 'Basic',
        pro: 'Priority',
        team: 'Priority'
      },
      {
        name: 'Dedicated Support',
        starter: false,
        pro: false,
        team: true
      },
      {
        name: 'Custom Development',
        starter: false,
        pro: false,
        team: true
      }
    ]
  }
];

export const FeatureComparison: React.FC = () => {
  const { isDarkMode } = useTheme();

  const renderCell = (value: boolean | string) => {
    if (typeof value === 'boolean') {
      return value ? (
        <Check className="w-5 h-5 text-green-500 mx-auto" />
      ) : (
        <Minus className="w-5 h-5 text-gray-400 mx-auto" />
      );
    }
    return <span className="text-sm">{value}</span>;
  };

  return (
    <div className="mt-24 max-w-7xl mx-auto">
      <h2 className={`text-2xl font-bold text-center mb-12 ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        Compare Features
      </h2>

      <div className={`rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-lg`}>
        <table className="w-full">
          <thead>
            <tr className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <th className="py-4 px-6 text-left"></th>
              <th className="py-4 px-6 text-center">Starter</th>
              <th className="py-4 px-6 text-center">Pro</th>
              <th className="py-4 px-6 text-center">Team</th>
            </tr>
          </thead>
          <tbody>
            {features.map((category, i) => (
              <React.Fragment key={category.category}>
                <tr className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
                  <td 
                    colSpan={4} 
                    className={`py-3 px-6 font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}
                  >
                    {category.category}
                  </td>
                </tr>
                {category.features.map((feature, j) => (
                  <tr 
                    key={feature.name}
                    className={`
                      ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}
                      ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}
                      border-b
                    `}
                  >
                    <td className={`py-4 px-6 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      {feature.name}
                    </td>
                    <td className="py-4 px-6 text-center">
                      {renderCell(feature.starter)}
                    </td>
                    <td className="py-4 px-6 text-center">
                      {renderCell(feature.pro)}
                    </td>
                    <td className="py-4 px-6 text-center">
                      {renderCell(feature.team)}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
// src/components/widgets/WidgetCreatorWrapper.tsx
import React from 'react';
import { WidgetGenerator } from './WidgetGenerator';
import { WidgetType } from '../../types/widget';

interface WidgetCreatorWrapperProps {
  type?: WidgetType;
}

export const WidgetCreatorWrapper: React.FC<WidgetCreatorWrapperProps> = ({ type }) => {
  return <WidgetGenerator initialType={type} />;
};

export default WidgetCreatorWrapper;
// src/services/widgetService.ts

import { Widget, CreateWidgetInput, UpdateWidgetInput, WidgetType } from '../types/widget';
import { claudeService } from './claudeService';
import { creditService } from './creditService';
import api from './api';
import logger from '../config/logger';

export class WidgetService {
  private static instance: WidgetService;
  private widgets: Map<string, Widget> = new Map();
  private listeners: Set<() => void> = new Set();

  private constructor() {}

  static getInstance(): WidgetService {
    if (!WidgetService.instance) {
      WidgetService.instance = new WidgetService();
    }
    return WidgetService.instance;
  }

  async createWidget(input: CreateWidgetInput): Promise<Widget> {
    try {
      // Calculate credits cost
      const cost = creditService.calculateWidgetCost(input.prompt, input.type);

      // Generate widget code using Claude
      const generatedCode = await claudeService.generateWidget(input);

      // Create widget request data
      const widgetData = {
        name: input.name,
        type: input.type,
        prompt: input.prompt,
        description: input.description,
        code: generatedCode,
        config: input.config || {},
        content: {
          code: generatedCode,
          data: []
        },
        creditCost: cost
      };

      // Create the widget
      const { data } = await api.post<Widget>('/widgets', widgetData);

      // Update local cache
      this.widgets.set(data.id, data);
      this.notifyListeners();

      return data;
    } catch (error) {
      logger.error('Widget creation error:', error);
      throw this.handleError(error);
    }
  }

  async updateWidget(id: string, updates: UpdateWidgetInput): Promise<Widget> {
    try {
      const { data } = await api.put<Widget>(`/widgets/${id}`, updates);
      
      // If there's a new prompt, regenerate the code
      if (updates.prompt) {
        const currentWidget = await this.getWidget(id);
        const newCode = await claudeService.generateWidget({
          name: updates.name || currentWidget.name,
          type: currentWidget.type,
          prompt: updates.prompt,
          description: updates.description || currentWidget.description
        });
        
        data.code = newCode;
        data.content = { ...data.content, code: newCode };
        
        await api.put<Widget>(`/widgets/${id}`, { 
          ...updates,
          code: newCode,
          content: data.content
        });
      }
      
      this.widgets.set(data.id, data);
      this.notifyListeners();
      
      return data;
    } catch (error) {
      logger.error('Widget update error:', error);
      throw this.handleError(error);
    }
  }

  async getWidget(id: string): Promise<Widget> {
    try {
      const { data } = await api.get<Widget>(`/widgets/${id}`);
      this.widgets.set(data.id, data);
      this.notifyListeners();
      return data;
    } catch (error) {
      logger.error('Error fetching widget:', error);
      throw this.handleError(error);
    }
  }

  async getUserWidgets(): Promise<Widget[]> {
    try {
      const { data } = await api.get<Widget[]>('/widgets');
      data.forEach(widget => this.widgets.set(widget.id, widget));
      this.notifyListeners();
      return data;
    } catch (error) {
      logger.error('Error fetching widgets:', error);
      throw new Error('Failed to fetch widgets');
    }
  }

  async deleteWidget(id: string): Promise<void> {
    try {
      await api.delete(`/widgets/${id}`);
      this.widgets.delete(id);
      this.notifyListeners();
    } catch (error) {
      logger.error('Widget deletion error:', error);
      throw this.handleError(error);
    }
  }

  subscribe(listener: () => void): () => void {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  private notifyListeners() {
    this.listeners.forEach(listener => listener());
  }

  private handleError(error: unknown): Error {
    if (error instanceof Error) {
      return error;
    }
    return new Error('An unknown error occurred');
  }
}

export const widgetService = WidgetService.getInstance();
// client/src/pages/About.tsx
import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { 
  TrendingUp,
  LineChart,
  Users,
  Globe,
  Award,
  BarChart2,
  Database,
  Code2,
  ExternalLink
} from 'lucide-react';

interface TeamMember {
  name: string;
  role: string;
  image: string;
  linkedIn: string;
  background: string;
}

interface Milestone {
  year: string;
  title: string;
  description: string;
}

export default () => {
  const { isDarkMode } = useTheme();

  const stats = [
    {
      value: '2K+',
      label: 'Widgets Generated',
      icon: LineChart
    },
    {
      value: '120+',
      label: 'Active Traders',
      icon: Users
    },
    {
      value: '36+',
      label: 'Countries',
      icon: Globe
    },
    {
      value: '99.2%',
      label: 'Uptime',
      icon: TrendingUp
    }
  ];

  const teamMembers: TeamMember[] = [
    {
      name: 'Nik Zral',
      role: 'CEO & Founder',
      image: '/team/nik-zral.jpeg',
      linkedIn: 'linkelin.com/in/nikzral',
      background: 'UVic Gustavson BCom Student redefining trading technology'
    },
    {
      name: 'Marcus Rodriguez',
      role: 'CTO',
      image: '/team/marcus-rodriguez.jpg',
      linkedIn: '#',
      background: 'UVic Economics Student with a passion for FinTech'
    }
  ];

  const milestones: Milestone[] = [
    {
      year: '2021',
      title: 'Foundation',
      description: 'Newsroom founded with a vision to democratize trading technology through AI'
    },
    {
      year: '2022',
      title: 'First Release',
      description: 'Launched core, no UI beta version with basic widget generation capabilities'
    },
    {
      year: '2023',
      title: 'Major Expansion',
      description: 'Moved to a graphical webapp and worked on real-time market data integration'
    },
    {
      year: '2024',
      title: 'Global Growth',
      description: 'Expanded to serve institutional clients and launched Beta access'
    }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-blue-800 opacity-10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Making Trading Technology <br />Accessible to Everyone
            </h1>
            <p className={`text-xl mb-8 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              We're building the future of trading visualization and analysis,
              powered by artificial intelligence and machine learning.
            </p>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <div key={index} className="text-center">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 rounded-lg bg-blue-600">
                      <Icon className="w-6 h-6 text-white" />
                    </div>
                  </div>
                  <div className={`text-3xl font-bold mb-2 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {stat.value}
                  </div>
                  <div className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    {stat.label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Our Story Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid md:grid-cols-2 gap-16 items-center">
          <div>
            <h2 className={`text-3xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Our Story
            </h2>
            <div className={`space-y-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <p>
                Founded in 2021 by a team of trading technology experts, Newsroom emerged
                from a simple observation: sophisticated trading tools were only accessible
                to large institutions with significant resources.
              </p>
              <p>
                We set out to change this by combining cutting-edge AI technology with
                years of trading expertise to create a platform that could generate
                professional-grade trading widgets through natural language.
              </p>
              <p>
                Today, we serve thousands of traders across the globe, from individual
                retail traders to large financial institutions, all using our AI-powered
                platform to create custom trading visualizations and analysis tools.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {[BarChart2, Database, Code2, Award].map((Icon, index) => (
              <div
                key={index}
                className={`aspect-square rounded-xl ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-lg flex items-center justify-center`}
              >
                <Icon className={`w-12 h-12 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Timeline Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-24`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-16 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Our Journey
          </h2>
          <div className="relative">
            {/* Timeline line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 w-px h-full bg-blue-600" />
            
            {/* Milestones */}
            <div className="space-y-24">
              {milestones.map((milestone, index) => (
                <div key={index} className={`relative ${
                  index % 2 === 0 ? 'md:text-right' : 'md:text-left'
                }`}>
                  <div className={`flex items-center ${
                    index % 2 === 0 ? 'md:justify-end' : 'md:justify-start'
                  } mb-4`}>
                    <div className={`absolute left-1/2 transform -translate-x-1/2
                      w-4 h-4 rounded-full bg-blue-600 border-4 ${
                      isDarkMode ? 'border-gray-800' : 'border-white'
                    }`} />
                    <div className={`text-xl font-bold ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {milestone.year}
                    </div>
                  </div>
                  <div className={`md:w-1/2 ${index % 2 === 0 ? 'md:mr-auto' : 'md:ml-auto'}`}>
                    <h3 className={`text-xl font-bold mb-2 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {milestone.title}
                    </h3>
                    <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                      {milestone.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Team Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <h2 className={`text-3xl font-bold text-center mb-16 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Meet Our Team
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`rounded-xl ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              } shadow-lg p-6`}
            >
              <div className="text-center mb-4">
  <div className="w-24 h-24 rounded-full mx-auto mb-4 overflow-hidden">
    {member.image ? (
      <img
        src={member.image}
        alt={member.name}
        className="w-full h-full object-cover"
        onError={(e) => {
            // Fallback to placeholder if image fails to load
          const target = e.target as HTMLImageElement;
          target.onerror = null;
          target.parentElement!.innerHTML = `
            <div class="${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
            } w-full h-full flex items-center justify-center">
              <svg class="w-12 h-12 ${
                isDarkMode ? 'text-gray-500' : 'text-gray-400'
              }" ...>
                <!-- Your Users icon SVG path -->
              </svg>
            </div>
          `;
        }}
      />
    ) : (
      <div className={`w-full h-full ${
        isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
      } flex items-center justify-center`}>
        <Users className={`w-12 h-12 ${
          isDarkMode ? 'text-gray-500' : 'text-gray-400'
        }`} />
      </div>
    )}
                </div>
                <h3 className={`text-lg font-bold mb-1 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {member.name}
                </h3>
                <div className={`text-sm mb-2 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`}>
                  {member.role}
                </div>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {member.background}
                </p>
                <a
                  href={member.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center mt-4 text-sm text-blue-500 hover:text-blue-600"
                >
                  LinkedIn
                  <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-24`}>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className={`text-3xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Join Us in Shaping the Future of Trading
          </h2>
          <p className={`text-lg mb-8 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Whether you're an individual trader or a large institution,
            we're here to help you build better trading tools.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/register"
              className="px-8 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              Get Started Free
            </Link>
            <Link
              to="/contact"
              className={`px-8 py-3 rounded-lg ${
                isDarkMode
                  ? 'bg-gray-700 text-white hover:bg-gray-600'
                  : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              } transition-colors`}
            >
              Contact Sales
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
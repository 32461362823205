import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';


import { 
  Book,
  Code,
  FileText,
  Video,
  ExternalLink,
  ChevronRight,
  BookOpen,
  Lightbulb,
  Library,
  Compass
} from 'lucide-react';

const Documentation = () => {
  const { isDarkMode } = useTheme();

  const sections = [
    {
      title: 'Getting Started',
      icon: Compass,
      links: [
        { name: 'Quick Start Guide', href: '/docs/quick-start' },
        { name: 'Installation', href: '/docs/installation' },
        { name: 'Basic Concepts', href: '/docs/concepts' },
        { name: 'First Widget', href: '/docs/first-widget' },
      ]
    },
    {
      title: 'Core Features',
      icon: Library,
      links: [
        { name: 'Widget Types', href: '/docs/widget-types' },
        { name: 'AI Integration', href: '/docs/ai-integration' },
        { name: 'Market Data', href: '/docs/market-data' },
        { name: 'Customization', href: '/docs/customization' },
      ]
    },
    {
      title: 'API Reference',
      icon: Code,
      links: [
        { name: 'Authentication', href: '/docs/api#authentication' },
        { name: 'Endpoints', href: '/docs/api#endpoints' },
        { name: 'Rate Limits', href: '/docs/api#rate-limits' },
        { name: 'Error Handling', href: '/docs/api#errors' },
      ]
    },
    {
      title: 'Resources',
      icon: Lightbulb,
      links: [
        { name: 'Tutorials', href: '/docs/tutorials' },
        { name: 'Examples', href: '/docs/examples' },
        { name: 'Best Practices', href: '/docs/best-practices' },
        { name: 'FAQs', href: '/docs/faqs' },
      ]
    }
  ];

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <BookOpen className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Documentation
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Everything you need to build amazing trading widgets with Newsroom.
              From getting started guides to advanced API documentation.
            </p>
          </div>
        </div>
      </div>

      {/* Documentation Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-8">
          {sections.map((section) => {
            const Icon = section.icon;
            return (
              <div
                key={section.title}
                className={`p-6 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700' 
                    : 'bg-white border-gray-200'
                }`}
              >
                <div className="flex items-center space-x-3 mb-6">
                  <div className="p-2 bg-blue-600 rounded-lg">
                    <Icon className="w-6 h-6 text-white" />
                  </div>
                  <h2 className={`text-xl font-bold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {section.title}
                  </h2>
                </div>

                <ul className="space-y-3">
                  {section.links.map((link) => (
                    <li key={link.name}>
                      <Link
                        to={link.href}
                        className={`group flex items-center justify-between p-3 rounded-lg transition-colors ${
                          isDarkMode
                            ? 'hover:bg-gray-700 text-gray-300'
                            : 'hover:bg-gray-50 text-gray-600'
                        }`}
                      >
                        <span>{link.name}</span>
                        <ChevronRight className={`w-5 h-5 transition-transform ${
                          isDarkMode ? 'text-gray-500' : 'text-gray-400'
                        } group-hover:translate-x-1`} />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>

      {/* Quick Links */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-3 gap-8">
            <Link
              to="/docs/api"
              className={`flex items-center p-6 rounded-lg border transition-colors ${
                isDarkMode 
                  ? 'bg-gray-900 border-gray-700 hover:border-blue-500' 
                  : 'bg-gray-50 border-gray-200 hover:border-blue-500'
              }`}
            >
              <Code className="w-6 h-6 text-blue-500 mr-3" />
              <div>
                <h3 className={`font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  API Reference
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Complete API documentation
                </p>
              </div>
            </Link>

            <Link
              to="/docs/tutorials"
              className={`flex items-center p-6 rounded-lg border transition-colors ${
                isDarkMode 
                  ? 'bg-gray-900 border-gray-700 hover:border-blue-500' 
                  : 'bg-gray-50 border-gray-200 hover:border-blue-500'
              }`}
            >
              <Video className="w-6 h-6 text-blue-500 mr-3" />
              <div>
                <h3 className={`font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Video Tutorials
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Step-by-step video guides
                </p>
              </div>
            </Link>

            <Link
              to="/docs/releases"
              className={`flex items-center p-6 rounded-lg border transition-colors ${
                isDarkMode 
                  ? 'bg-gray-900 border-gray-700 hover:border-blue-500' 
                  : 'bg-gray-50 border-gray-200 hover:border-blue-500'
              }`}
            >
              <FileText className="w-6 h-6 text-blue-500 mr-3" />
              <div>
                <h3 className={`font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Release Notes
                </h3>
                <p className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Latest updates and changes
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default Documentation;
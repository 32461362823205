import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { MarketingHeader } from '../components/layout/MarketingHeader';
import MobileWarning from '../components/MobileWarning';
import OctagonPattern from '../components/OctagonPattern';
import ScheduledDowntimeBanner from '../components/ScheduledDowntimeBanner';
import { SEOHead } from '../components/common/SEOHead';
import { SEO } from '../components/common/SEO';
import { 
  LayoutDashboard, 
  LineChart, 
  Zap,
  Bot,
  ArrowRight,
  Shield,
  Users,
  Code
} from 'lucide-react';

export const Home = () => {
  const { isDarkMode } = useTheme();
  const [showMobileWarning, setShowMobileWarning] = useState(true);
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Newsroom Widget Generator",
    "description": "AI-powered trading widget generator",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "applicationCategory": "FinanceApplication",
    "featureList": [
      "Real-time market data visualization",
      "AI-powered widget generation",
      "Custom technical indicators"
    ]
  };
  const features = [
    {
      icon: LineChart,
      title: 'AI-Powered Charts',
      description: 'Generate sophisticated trading visualizations using natural language'
    },
    {
      icon: Zap,
      title: 'Real-time Data',
      description: 'Access live market data and analytics in your custom widgets'
    },
    {
      icon: Shield,
      title: 'Enterprise Ready',
      description: 'Built for professional traders and institutions'
    },
    {
      icon: Code,
      title: 'Developer Friendly',
      description: 'Full API access and custom integrations'
    }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Header - Above all other content */}
      <SEO 
        title="Create AI Trading Widgets | Newsroom"
        description="Generate custom trading widgets with AI. Real-time market data visualization, technical analysis, and automated trading solutions."
        jsonLd={jsonLd}
      />
      
      
      {/* Main Content Container */}
      <div className="relative">
        {/* Background Pattern - Behind content */}
        
        <OctagonPattern />
        
        
        {/* Mobile Warning */}
        {showMobileWarning && (
          <MobileWarning onClose={() => setShowMobileWarning(false)} />
        )}
        
        {/* Hero Section */}
        <div className="relative pt-16"> {/* Added padding to account for header */}
          <div className="container mx-auto px-6 py-24">
            <div className="flex flex-col items-center text-center max-w-4xl mx-auto">
              <div className={`inline-flex items-center px-4 py-2 rounded-full mb-8 ${
                isDarkMode 
                  ? 'bg-blue-900/20 text-blue-400' 
                  : 'bg-blue-100 text-blue-600'
              }`}>
                <Zap className="w-4 h-4 mr-2" />
                AI-Powered Trading Widgets
              </div>
              
              <h1 className={`text-5xl md:text-6xl font-bold tracking-tight mb-8 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Transform Text into
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
                  {' '}Trading Widgets
                </span>
              </h1>
              
              <p className={`text-xl mb-12 max-w-2xl ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Generate sophisticated trading dashboards and widgets using natural language. 
                Powered by AI, built for traders.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4">
                <Link
                  to="/register"
                  className="inline-flex items-center px-8 py-4 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-all"
                >
                  <LayoutDashboard className="w-5 h-5 mr-2" />
                  Launch Platform
                  <ArrowRight className="w-5 h-5 ml-2" />
                </Link>
                
                <Link
                  to="/features"
                  className={`inline-flex items-center px-8 py-4 rounded-lg ${
                    isDarkMode
                      ? 'bg-gray-800 text-white hover:bg-gray-700'
                      : 'bg-white text-gray-900 hover:bg-gray-50'
                  } transition-all`}
                >
                  <Bot className="w-5 h-5 mr-2" />
                  See Features
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="relative">
          <div className="container mx-auto px-6 py-24">
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {features.map((feature, index) => {
                const Icon = feature.icon;
                return (
                  <div
                    key={index}
                    className={`p-6 rounded-xl ${
                      isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'
                    } backdrop-blur-sm border ${
                      isDarkMode ? 'border-gray-700' : 'border-gray-200'
                    } hover:scale-105 transition-transform duration-300`}
                  >
                    <div className="w-12 h-12 rounded-lg bg-blue-600 flex items-center justify-center mb-4">
                      <Icon className="text-white w-6 h-6" />
                    </div>
                    <h3 className={`text-xl font-bold mb-2 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {feature.title}
                    </h3>
                    <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                      {feature.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
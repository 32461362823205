import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';


import { 
  FileText, 
  Sparkles, 
  Bug, 
  Zap,
  Shield,
  RefreshCw,
  ArrowUp
} from 'lucide-react';

interface Release {
  version: string;
  date: string;
  type: 'major' | 'minor' | 'patch';
  features?: string[];
  improvements?: string[];
  fixes?: string[];
  security?: string[];
}

const releases: Release[] = [
  {
    version: '2.1.0',
    date: 'March 15, 2024',
    type: 'minor',
    features: [
      'Added AI-powered widget refinement',
      'New market data integration options',
      'Improved real-time data streaming'
    ],
    improvements: [
      'Enhanced widget customization interface',
      'Optimized data processing pipeline',
      'Better error handling and feedback'
    ],
    fixes: [
      'Fixed widget refresh issues',
      'Resolved data synchronization bugs',
      'Corrected timezone handling'
    ]
  },
  {
    version: '2.0.0',
    date: 'February 1, 2024',
    type: 'major',
    features: [
      'Complete UI/UX overhaul',
      'New widget generation engine',
      'Advanced analytics dashboard'
    ],
    improvements: [
      'Significant performance improvements',
      'Enhanced mobile responsiveness',
      'Streamlined widget creation workflow'
    ],
    security: [
      'Updated authentication system',
      'Enhanced data encryption',
      'Improved API security'
    ]
  },
  // Add more releases...
];

const ReleaseNotes = () => {
  const { isDarkMode } = useTheme();

  const getVersionBadgeColor = (type: Release['type']) => {
    switch (type) {
      case 'major':
        return 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300';
      case 'minor':
        return 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300';
      case 'patch':
        return 'bg-purple-100 text-purple-700 dark:bg-purple-900 dark:text-purple-300';
      default:
        return 'bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300';
    }
  };

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <FileText className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Release Notes
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Stay up to date with the latest features, improvements, and fixes in Newsroom.
            </p>
          </div>
        </div>
      </div>

      {/* Releases Timeline */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="space-y-16">
          {releases.map((release, index) => (
            <div 
              key={release.version}
              className={`relative ${
                index !== releases.length - 1 ? 'pb-16 border-l-2' : ''
              } ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}
            >
              <div className="relative">
                {/* Version Header */}
                <div className="flex items-center mb-6">
                  <div className={`px-4 py-1 rounded-full text-sm font-medium ${
                    getVersionBadgeColor(release.type)
                  }`}>
                    Version {release.version}
                  </div>
                  <span className={`ml-4 text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    {release.date}
                  </span>
                </div>

                {/* Release Content */}
                <div className={`p-6 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700' 
                    : 'bg-white border-gray-200'
                }`}>
                  {/* Features */}
                  {release.features && (
                    <div className="mb-6">
                      <div className="flex items-center mb-3">
                        <Sparkles className="w-5 h-5 text-blue-500 mr-2" />
                        <h3 className={`font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          New Features
                        </h3>
                      </div>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {release.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <ArrowUp className="w-4 h-4 mr-2 mt-1 text-green-500" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Improvements */}
                  {release.improvements && (
                    <div className="mb-6">
                      <div className="flex items-center mb-3">
                        <Zap className="w-5 h-5 text-yellow-500 mr-2" />
                        <h3 className={`font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          Improvements
                        </h3>
                      </div>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {release.improvements.map((improvement, i) => (
                          <li key={i} className="flex items-start">
                            <RefreshCw className="w-4 h-4 mr-2 mt-1 text-blue-500" />
                            {improvement}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Bug Fixes */}
                  {release.fixes && (
                    <div className="mb-6">
                      <div className="flex items-center mb-3">
                        <Bug className="w-5 h-5 text-red-500 mr-2" />
                        <h3 className={`font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          Bug Fixes
                        </h3>
                      </div>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {release.fixes.map((fix, i) => (
                          <li key={i} className="flex items-start">
                            <div className="w-4 h-4 mr-2 mt-1 flex-shrink-0">•</div>
                            {fix}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Security Updates */}
                  {release.security && (
                    <div>
                      <div className="flex items-center mb-3">
                        <Shield className="w-5 h-5 text-purple-500 mr-2" />
                        <h3 className={`font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          Security Updates
                        </h3>
                      </div>
                      <ul className={`space-y-2 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {release.security.map((update, i) => (
                          <li key={i} className="flex items-start">
                            <div className="w-4 h-4 mr-2 mt-1 flex-shrink-0">•</div>
                            {update}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

     
    </div>
  );
};

export default ReleaseNotes;
// src/components/auth/OAuthCallback.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { socialAuthService } from '../../services/socialAuthService';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Alert } from '../ui/alert';
import { SocialProvider, SocialAuthCredentials } from '../../types/auth';

const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { socialAuth } = useAuth();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      const provider = location.pathname.split('/').pop() as SocialProvider;

      if (!code || !state || !provider) {
        setError('Invalid callback parameters');
        return;
      }

      try {
        const credentials: SocialAuthCredentials = {
          provider,
          code,
          state
        };

        // Validate the callback first
        await socialAuthService.validateCallback(credentials);
        
        // Then process the social authentication
        await socialAuth(credentials);
        navigate('/dashboard');
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Authentication failed. Please try again.');
      }
    };

    handleCallback();
  }, [location, navigate, socialAuth]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <Alert variant="destructive">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <LoadingSpinner size="lg" message="Completing authentication..." />
    </div>
  );
};

export default OAuthCallback;
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import { authService } from '../../services/authService';
import { RegisterFormData, ValidationErrors, PasswordStrength, TradingRole, ExperienceLevel } from '../../types/register';
import { 
  Key,
  Mail, 
  Lock, 
  User, 
  EyeOff, 
  Eye, 
  Github, 
  Linkedin,
  ArrowLeft,
  CheckCircle2,
  AlertCircle,
  Loader2,
  ChevronRight
} from 'lucide-react';
import { RegisterCredentials } from '../../types/auth';

const initialFormData: RegisterFormData = {
  name: '',
  email: '',
  password: '',
  role: '',
  experience: '',
  betaCode: ''
};

const initialStrength: PasswordStrength = {
  score: 0,
  hasLength: false,
  hasUpperCase: false,
  hasLowerCase: false,
  hasNumber: false,
  hasSpecial: false
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<RegisterFormData>(initialFormData);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [strength, setStrength] = useState<PasswordStrength>(initialStrength);

  const validatePassword = (password: string) => {
    const newStrength = {
      score: 0,
      hasLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
  
    let score = 0;
    if (newStrength.hasLength) score++;
    if (newStrength.hasUpperCase) score++;
    if (newStrength.hasLowerCase) score++;
    if (newStrength.hasNumber) score++;
    if (newStrength.hasSpecial) score++;
    
    newStrength.score = score;
    setStrength(newStrength);
    
    return score >= 4;
  };

  const validateStep = (stepNumber: number): boolean => {
    const newErrors: ValidationErrors = {};

    if (stepNumber === 1) {
      if (!formData.email) newErrors.email = 'Email is required';
      if (!formData.password) {
        newErrors.password = 'Password is required';
      } else if (!validatePassword(formData.password)) {
        newErrors.password = 'Password does not meet requirements';
      }
      if (!formData.betaCode) newErrors.betaCode = 'Beta code is required';
    } else if (stepNumber === 2) {
      if (!formData.name) newErrors.name = 'Name is required';
      if (!formData.role) newErrors.role = 'Role is required';
      if (!formData.experience) newErrors.experience = 'Experience level is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (name === 'password') {
      validatePassword(value);
    }
  };

  const handleContinue = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateStep(1)) {
      return;
    }

    try {
      const response = await authService.validateBetaCode(formData.betaCode);
      if (response.isValid) {
        setStep(2);
      } else {
        setErrors(prev => ({
          ...prev,
          betaCode: response.message || 'Invalid beta code'
        }));
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        betaCode: 'Error validating beta code'
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateStep(2)) {
      return;
    }

    setIsSubmitting(true);
    try {
      await authService.register({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role as TradingRole,
        experience: formData.experience as ExperienceLevel,
        betaCode: formData.betaCode
      });
      navigate('/dashboard');
    } catch (error) {
      setErrors({
        general: error instanceof Error ? error.message : 'Registration failed'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPasswordStrength = () => (
    <div className="mt-2 space-y-2">
      <div className="flex gap-2">
        {[1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            className={`h-2 w-full rounded ${
              index <= strength.score
                ? strength.score >= 4
                  ? 'bg-green-500'
                  : strength.score >= 3
                  ? 'bg-yellow-500'
                  : 'bg-red-500'
                : 'bg-gray-200 dark:bg-gray-700'
            }`}
          />
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2 text-sm">
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasLength ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasLength ? 'text-green-500' : 'text-gray-500'}>8+ characters</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasUpperCase ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasUpperCase ? 'text-green-500' : 'text-gray-500'}>Uppercase</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasLowerCase ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasLowerCase ? 'text-green-500' : 'text-gray-500'}>Lowercase</span>
        </div>
        <div className="flex items-center gap-1">
          <CheckCircle2 className={`h-4 w-4 ${strength.hasNumber ? 'text-green-500' : 'text-gray-400'}`} />
          <span className={strength.hasNumber ? 'text-green-500' : 'text-gray-500'}>Number</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`w-full max-w-md p-8 rounded-2xl shadow-xl ${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    }`}>
      <div className="text-center mb-8">
        <Link to="/" className="inline-block mb-6">
          <img
            src={isDarkMode ? '/logo-dark.png' : '/logo-light.png'}
            alt="Newsroom"
            className="h-8"
          />
        </Link>
        <h2 className={`text-2xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          {step === 1 ? 'Create your account' : 'Complete your profile'}
        </h2>
        <p className={`mt-2 text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          {step === 1 
            ? 'Start creating AI-powered trading widgets in minutes'
            : 'Tell us about yourself to personalize your experience'
          }
        </p>
      </div>

      {/* Progress Steps */}
      <div className="mb-8">
        <div className="flex justify-between">
          {[1, 2].map((stepNumber) => (
            <div
              key={stepNumber}
              className={`flex items-center ${stepNumber === 2 ? 'flex-1 ml-2' : 'flex-1'}`}
            >
              <div className={`relative flex items-center justify-center w-8 h-8 rounded-full border-2 ${
                step >= stepNumber
                  ? 'border-blue-500 bg-blue-500 text-white'
                  : isDarkMode
                    ? 'border-gray-600 text-gray-400'
                    : 'border-gray-300 text-gray-500'
              }`}>
                {step > stepNumber ? (
                  <CheckCircle2 className="w-5 h-5" />
                ) : (
                  <span>{stepNumber}</span>
                )}
              </div>
              {stepNumber === 1 && (
                <div className={`flex-1 h-0.5 mx-4 ${
                  step > 1
                    ? 'bg-blue-500'
                    : isDarkMode
                      ? 'bg-gray-600'
                      : 'bg-gray-300'
                }`} />
              )}
            </div>
          ))}
        </div>
      </div>

      {errors.general && (
        <div className="mb-6 p-3 rounded-lg bg-red-50 border border-red-200 text-red-600 flex items-center space-x-2">
          <AlertCircle className="w-5 h-5" />
          <span className="text-sm">{errors.general}</span>
        </div>
      )}

      <form onSubmit={step === 1 ? handleContinue : handleSubmit} className="space-y-6">
        {step === 1 ? (
          <>
            {/* Step 1: Account Creation */}
            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Email address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="you@example.com"
                />
              </div>
              {errors.email && (
                <p className="mt-1.5 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-10 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {renderPasswordStrength()}
              {errors.password && (
                <p className="mt-1.5 text-sm text-red-500">{errors.password}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Beta Code
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Key className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type="text"
                  name="betaCode"
                  value={formData.betaCode}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="Enter your beta code"
                />
              </div>
              {errors.betaCode && (
                <p className="mt-1.5 text-sm text-red-500">{errors.betaCode}</p>
              )}
              <p className={`mt-1.5 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Need a beta code?{' '}
                <a 
                  href="https://news-room.ca/beta" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-500 hover:text-blue-600"
                >
                  Request access
                </a>
              </p>
            </div>
          </>
        ) : (
          <>
            {/* Step 2: Profile Information */}
            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Full name
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className={`h-5 w-5 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`} />
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`block w-full pl-10 pr-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  placeholder="John Doe"
                />
              </div>
              {errors.name && (
                <p className="mt-1.5 text-sm text-red-500">{errors.name}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Trading role
              </label>
              <select
                name="role"
                value={formData.role}
                onChange={handleChange}
                className={`block w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              >
                <option value="">Select your role</option>
                <option value="individual">Individual Trader</option>
                <option value="professional">Professional Trader</option>
                <option value="institutional">Institutional Trader</option>
                <option value="developer">Trading Developer</option>
                <option value="analyst">Market Analyst</option>
              </select>
              {errors.role && (
                <p className="mt-1.5 text-sm text-red-500">{errors.role}</p>
              )}
            </div>

            <div>
              <label className={`block text-sm font-medium mb-1.5 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Trading experience
              </label>
              <select
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                className={`block w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              >
                <option value="">Select your experience</option>
                <option value="beginner">Beginner (0-2 years)</option>
                <option value="intermediate">Intermediate (2-5 years)</option>
                <option value="advanced">Advanced (5-10 years)</option>
                <option value="expert">Expert (10+ years)</option>
              </select>
              {errors.experience && (
                <p className="mt-1.5 text-sm text-red-500">{errors.experience}</p>
              )}
            </div>
          </>
        )}

        <div className="space-y-4">
          {step === 1 ? (
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex items-center justify-center px-4 py-2.5 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              Continue
              <ChevronRight className="ml-2 h-5 w-5" />
            </button>
          ) : (
            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => setStep(1)}
                className={`flex-1 flex items-center justify-center px-4 py-2.5 border text-sm font-medium rounded-lg ${
                  isDarkMode
                    ? 'border-gray-600 text-gray-300 hover:bg-gray-700'
                    : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                <ArrowLeft className="mr-2 h-5 w-5" />
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex-1 flex items-center justify-center px-4 py-2.5 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <>
                    Complete Sign Up
                    <ChevronRight className="ml-2 h-5 w-5" />
                  </>
                )}
              </button>
            </div>
          )}
        </div>

        {step === 1 && (
          <>
            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className={`w-full border-t ${
                  isDarkMode ? 'border-gray-700' : 'border-gray-300'
                }`}></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className={`px-2 ${
                  isDarkMode ? 'bg-gray-800 text-gray-400' : 'bg-white text-gray-500'
                }`}>
                  Or continue with
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3">
              <button
                type="button"
                className={`flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-lg ${
                  isDarkMode
                    ? 'border-gray-600 text-gray-300 hover:bg-gray-700'
                    : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Github className="mr-2 h-5 w-5" />
                GitHub
              </button>
              <button
                type="button"
                className={`flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-lg ${
                  isDarkMode
                    ? 'border-gray-600 text-gray-300 hover:bg-gray-700'
                    : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                <Linkedin className="mr-2 h-5 w-5" />
                LinkedIn
              </button>
            </div>
          </>
        )}

        <p className="mt-6 text-center text-sm text-gray-500">
          Already have an account?{' '}
          <Link
            to="/login"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Sign in
          </Link>
        </p>
      </form>
    </div>
  );
};

export default RegisterForm;
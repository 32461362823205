// client/src/services/contactService.ts
import { api } from './api';

interface ContactFormData {
  name: string;
  email: string;
  company: string;
  inquiryType: string;
  message: string;
}

export const submitContactForm = async (data: ContactFormData) => {
  const { data: response } = await api.post('/contact/submit', data);
  return response;
};
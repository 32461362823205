type LogLevel = 'info' | 'warn' | 'error' | 'debug';

class Logger {
  private isDev = process.env.NODE_ENV === 'development';

  private formatMessage(level: LogLevel, message: string, data?: any): string {
    const timestamp = new Date().toISOString();
    return `[${timestamp}] [${level.toUpperCase()}] ${message}${data ? '\n' + JSON.stringify(data, null, 2) : ''}`;
  }

  info(message: string, data?: any) {
    if (this.isDev) {
      console.info(this.formatMessage('info', message, data));
    }
  }

  warn(message: string, data?: any) {
    console.warn(this.formatMessage('warn', message, data));
  }

  error(message: string, error?: any) {
    console.error(this.formatMessage('error', message, error));
  }

  debug(message: string, data?: any) {
    if (this.isDev) {
      console.debug(this.formatMessage('debug', message, data));
    }
  }
}

export const logger = new Logger();
export default logger;
// src/pages/resources/newsletter.tsx
import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../../components/ui/card';
import { Mail, CheckCircle2, AlertCircle, ArrowRight } from 'lucide-react';

const NewsletterPage = () => {
  const { isDarkMode } = useTheme();
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!email) {
      setError('Please enter your email address');
      return;
    }

    try {
      // API call would go here
      setSubscribed(true);
    } catch (err) {
      setError('Failed to subscribe. Please try again.');
    }
  };

  return (
   
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Mail className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Stay Updated
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Get the latest updates, trading insights, and widget tips delivered to your inbox
              </p>
            </div>
          </div>
        </div>

        {/* Newsletter Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid md:grid-cols-2 gap-8">
            {/* Subscription Form */}
            <Card className={`${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
            }`}>
              <CardHeader>
                <CardTitle className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                  Subscribe to Our Newsletter
                </CardTitle>
                <CardDescription className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  Join thousands of traders receiving our weekly insights
                </CardDescription>
              </CardHeader>
              <CardContent>
                {subscribed ? (
                  <div className="flex items-center space-x-2 text-green-500">
                    <CheckCircle2 className="w-5 h-5" />
                    <span>Thanks for subscribing!</span>
                  </div>
                ) : (
                  <form onSubmit={handleSubscribe} className="space-y-4">
                    <div>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`w-full px-4 py-2 rounded-lg ${
                          isDarkMode
                            ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                            : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                      />
                    </div>
                    {error && (
                      <div className="flex items-center space-x-2 text-red-500">
                        <AlertCircle className="w-5 h-5" />
                        <span>{error}</span>
                      </div>
                    )}
                    <button
                      type="submit"
                      className="w-full px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                    >
                      Subscribe
                    </button>
                  </form>
                )}
              </CardContent>
            </Card>

            {/* Benefits */}
            <div className="space-y-6">
              <h3 className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                What You'll Get
              </h3>
              {[
                {
                  title: 'Weekly Market Insights',
                  description: 'Expert analysis and trading opportunities'
                },
                {
                  title: 'Widget Tips & Tricks',
                  description: 'Learn how to maximize your widget efficiency'
                },
                {
                  title: 'Product Updates',
                  description: 'Be the first to know about new features'
                },
                {
                  title: 'Trading Strategies',
                  description: 'Advanced strategies from professional traders'
                }
              ].map((benefit, index) => (
                <div
                  key={index}
                  className={`p-4 rounded-lg ${
                    isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border border-gray-200'
                  }`}
                >
                  <h4 className={`font-medium mb-2 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {benefit.title}
                  </h4>
                  <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default NewsletterPage;
// client/src/App.tsx
import React from 'react';
import { AppRouter } from './router/AppRouter';
import { AuthProvider } from './contexts/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from './contexts/ThemeContext';

function App() {
  return (
    <HelmetProvider>
    <ThemeProvider>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;

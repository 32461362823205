// client/src/components/common/CreditDisplay.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Coins } from 'lucide-react';
import { creditService } from '../../services/creditService';
import { useTheme } from '../../contexts/ThemeContext';

interface CreditDisplayProps {
  showBuyButton?: boolean;
  className?: string;
}

export const CreditDisplay: React.FC<CreditDisplayProps> = ({ 
  showBuyButton = true,
  className = '' 
}) => {
  const [credits, setCredits] = useState<number>(0);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    loadCredits();
  }, []);

  const loadCredits = async () => {
    try {
      const currentCredits = await creditService.getCurrentCredits();
      setCredits(currentCredits);
    } catch (err) {
      console.error('Failed to load credits');
    }
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <div className={`flex items-center gap-1 ${
        isDarkMode ? 'text-gray-300' : 'text-gray-700'
      }`}>
        <Coins className="w-4 h-4" />
        <span className="font-medium">{credits}</span>
        <span className="text-sm">credits</span>
      </div>
      {showBuyButton && (
        <Link
          to="/dashboard/credits"
          className="text-sm px-2 py-1 rounded bg-blue-600 text-white hover:bg-blue-700"
        >
          Buy More
        </Link>
      )}
    </div>
  );
};
// src/pages/dashboard/Dashboard.tsx
import { Routes, Route } from 'react-router-dom';
import { DashboardLayout } from './DashboardLayout';
import { WidgetCreatorWrapper } from '../../components/widgets/WidgetCreatorWrapper';
import { WidgetLibrary } from '../../components/widgets/WidgetLibrary';
import { WidgetSettings } from '../../components/widgets/WidgetSettings';
import { Credits } from './Credits';
import { WidgetCustomization } from '../../components/widgets/WidgetCustomization';
import MarketData from '../../components/MarketData';
import { WidgetType } from '../../types/widget';
import { Settings } from '../settings';
import { 
  WidgetGenerator
} from '../../components/widgets';

export const Dashboard: React.FC = () => {
  return (
    <DashboardLayout>
      <Routes>
        <Route index element={<WidgetLibrary />} />
        <Route path="create" element={<WidgetGenerator />} />
<Route path="create/:type" element={<WidgetCreatorWrapper />} />
        <Route path="library" element={<WidgetLibrary />} />
        <Route path="settings" element={<WidgetSettings />} />
        <Route path="credits" element={<Credits />} />
        <Route path="customize/:widgetId" element={<WidgetCustomization />} />
        <Route path="market" element={<MarketData />} />
      </Routes>
    </DashboardLayout>
  );
};
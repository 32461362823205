// src/pages/resources/help.tsx
import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../../components/ui/card';
import { 
  HelpCircle, 
  Search, 
  Book, 
  MessageCircle, 
  Phone,
  Mail,
  ChevronRight,
  ArrowRight,
  FileText,
  Users,
  Settings,
  Shield
} from 'lucide-react';

interface HelpCategory {
  id: string;
  title: string;
  description: string;
  icon: React.FC<{ className?: string }>;
  articles: Array<{
    id: string;
    title: string;
    excerpt: string;
  }>;
}

const helpCategories: HelpCategory[] = [
  {
    id: 'getting-started',
    title: 'Getting Started',
    description: 'Learn the basics of using Newsroom',
    icon: Book,
    articles: [
      {
        id: '1',
        title: 'Creating Your First Widget',
        excerpt: 'A step-by-step guide to creating your first trading widget'
      },
      // Add more articles...
    ]
  },
  {
    id: 'account',
    title: 'Account & Billing',
    description: 'Manage your account and subscription',
    icon: Users,
    articles: [
      {
        id: '2',
        title: 'Managing Your Subscription',
        excerpt: 'Learn how to manage your subscription and billing preferences'
      }
    ]
  },
  {
    id: 'widgets',
    title: 'Widget Configuration',
    description: 'Customize and optimize your widgets',
    icon: Settings,
    articles: []
  },
  {
    id: 'security',
    title: 'Security',
    description: 'Keep your account and data secure',
    icon: Shield,
    articles: []
  }
];

// src/pages/resources/help.tsx (continued)

const commonQuestions = [
    {
      question: 'How do I reset my password?',
      answer: 'You can reset your password by clicking the "Forgot Password" link on the login page. We will send you an email with reset instructions.'
    },
    {
      question: 'How are credits calculated for widget generation?',
      answer: 'Credits are calculated based on widget complexity, data requirements, and customization options. Basic widgets typically cost 10-20 credits.'
    },
    {
      question: 'Can I export my widget data?',
      answer: 'Yes, you can export widget data in CSV, JSON, or Excel format from the widget settings menu.'
    },
    {
      question: 'How often is market data updated?',
      answer: 'Market data is updated in real-time for premium subscriptions, and every 5 minutes for standard accounts.'
    }
  ];
  
  const HelpCenter = () => {
    const { isDarkMode } = useTheme();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  
    const filteredCategories = helpCategories.filter(category =>
      category.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      category.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      category.articles.some(article => 
        article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        article.excerpt.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  
    return (
      
        <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
          {/* Hero Section */}
          <div className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
              <div className="text-center">
                <HelpCircle className={`w-12 h-12 mx-auto mb-6 ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-600'
                }`} />
                <h1 className={`text-4xl font-bold mb-6 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  How can we help?
                </h1>
                <div className="max-w-2xl mx-auto">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search for help..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className={`w-full px-4 py-3 pl-12 rounded-lg ${
                        isDarkMode
                          ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-400'
                          : 'bg-white border-gray-200 text-gray-900 placeholder-gray-500'
                      } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                    />
                    <Search className={`absolute left-4 top-3.5 w-5 h-5 ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* Main Content */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            {/* Help Categories */}
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
              {filteredCategories.map((category) => {
                const Icon = category.icon;
                return (
                  <Card
                    key={category.id}
                    className={`group cursor-pointer hover:shadow-lg transition-shadow ${
                      isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
                    }`}
                    onClick={() => setSelectedCategory(category.id)}
                  >
                    <CardContent className="p-6">
                      <div className={`w-12 h-12 rounded-lg bg-blue-600 flex items-center justify-center mb-4`}>
                        <Icon className="w-6 h-6 text-white" />
                      </div>
                      <h3 className={`text-lg font-bold mb-2 group-hover:text-blue-500 transition-colors ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {category.title}
                      </h3>
                      <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                        {category.description}
                      </p>
                      <div className={`mt-4 flex items-center text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        <span>{category.articles.length} articles</span>
                        <ChevronRight className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform" />
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
  
            {/* Common Questions */}
            <div className="mb-16">
              <h2 className={`text-2xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Frequently Asked Questions
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {commonQuestions.map((item, index) => (
                  <div
                    key={index}
                    className={`p-6 rounded-lg ${
                      isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border border-gray-200'
                    }`}
                  >
                    <h3 className={`text-lg font-medium mb-2 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {item.question}
                    </h3>
                    <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                      {item.answer}
                    </p>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Contact Options */}
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  icon: MessageCircle,
                  title: 'Live Chat',
                  description: 'Chat with our support team',
                  action: 'Start Chat',
                  color: 'bg-blue-600'
                },
                {
                  icon: Mail,
                  title: 'Email Support',
                  description: 'Get help via email',
                  action: 'Send Email',
                  color: 'bg-green-600'
                },
                {
                  icon: Phone,
                  title: 'Phone Support',
                  description: 'Speak with an expert',
                  action: 'Call Now',
                  color: 'bg-purple-600'
                }
              ].map((option, index) => {
                const Icon = option.icon;
                return (
                  <Card
                    key={index}
                    className={`group cursor-pointer hover:shadow-lg transition-shadow ${
                      isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
                    }`}
                  >
                    <CardContent className="p-6">
                      <div className={`w-12 h-12 rounded-lg ${option.color} flex items-center justify-center mb-4`}>
                        <Icon className="w-6 h-6 text-white" />
                      </div>
                      <h3 className={`text-lg font-bold mb-2 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {option.title}
                      </h3>
                      <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        {option.description}
                      </p>
                      <button className={`flex items-center text-sm font-medium ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-600'
                      } group-hover:text-blue-500`}>
                        {option.action}
                        <ArrowRight className="w-4 h-4 ml-1 group-hover:translate-x-1 transition-transform" />
                      </button>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
     
    );
  };
  
  export default HelpCenter;
import { api } from './api';
import { CreateWidgetInput } from '../types/widget';
import { AIMessage } from '../types/shared';

export class ClaudeService {
  private static instance: ClaudeService;

  private constructor() {}

  static getInstance(): ClaudeService {
    if (!ClaudeService.instance) {
      ClaudeService.instance = new ClaudeService();
    }
    return ClaudeService.instance;
  }

  async generateWidget(input: CreateWidgetInput): Promise<string> {
    try {
      const { data } = await api.post('/claude/generate', input);
      return data.content;
    } catch (error) {
      console.error('Widget generation failed:', error);
      throw error;
    }
  }

  async generateWidgetRefinement(
    widget: any,
    instruction: string,
    context: AIMessage[]
  ): Promise<string> {
    try {
      const { data } = await api.post('/claude/refine', {
        widget,
        instruction,
        context
      });
      return data.content;
    } catch (error) {
      console.error('Widget refinement failed:', error);
      throw error;
    }
  }
}

export const claudeService = ClaudeService.getInstance();
// client/src/pages/pricing/PricingPlans.tsx
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { Check, Zap, Package, Users } from 'lucide-react';

interface Subscription {
  name: string;
  price: number;
  monthlyCredits: number;
  features: string[];
  popular?: boolean;
}

interface PayAsYouGoTier {
  credits: number;
  price: number;
  popular?: boolean;
}

interface PricingPlansProps {
  isAnnual: boolean;
  showSubscriptions: boolean;
  subscriptions: Subscription[];
  payAsYouGo: PayAsYouGoTier[];
}

const SubscriptionCard: React.FC<{
  plan: Subscription;
  isAnnual: boolean;
}> = ({ plan, isAnnual }) => {
  const { isDarkMode } = useTheme();
  const actualPrice = isAnnual ? Math.floor(plan.price * 0.8) : plan.price;
  
  return (
    <div className={`relative rounded-2xl ${
      plan.popular 
        ? 'border-2 border-blue-500'
        : isDarkMode ? 'border border-gray-700' : 'border border-gray-200'
    } ${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    } p-8 shadow-lg flex flex-col`}>
      {plan.popular && (
        <div className="absolute -top-5 left-1/2 transform -translate-x-1/2">
          <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
            Most Popular
          </span>
        </div>
      )}

      <div className={`text-lg font-medium mb-2 ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        {plan.name}
      </div>

      <div className="mb-6">
        <span className="text-3xl font-bold">
          ${actualPrice}
        </span>
        <span className={`text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          /month
        </span>
        {isAnnual && (
          <div className="text-sm text-green-500 mt-1">
            Save 20% with annual billing
          </div>
        )}
      </div>

      <div className="mb-6">
        <div className={`text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          Monthly Credits
        </div>
        <div className="text-2xl font-bold text-blue-500">
          {plan.monthlyCredits.toLocaleString()}
        </div>
        <div className={`text-sm mt-1 ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          Auto-renews monthly
        </div>
      </div>

      <div className="space-y-3 mb-8 flex-grow">
        {plan.features.map((feature, index) => (
          <div key={index} className="flex items-start">
            <Check className={`w-5 h-5 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-500'
            } mr-2 flex-shrink-0`} />
            <span className={`text-sm ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              {feature}
            </span>
          </div>
        ))}
      </div>

      <Link
        to="/register"
        className={`w-full text-center py-3 px-4 rounded-lg font-medium ${
          plan.popular
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : isDarkMode
              ? 'bg-gray-700 text-white hover:bg-gray-600'
              : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
        } transition-colors`}
      >
        Get Started
      </Link>
    </div>
  );
};

const PayAsYouGoCard: React.FC<{
  tier: PayAsYouGoTier;
}> = ({ tier }) => {
  const { isDarkMode } = useTheme();
  const pricePerCredit = (tier.price / tier.credits).toFixed(3);
  
  return (
    <div className={`relative rounded-2xl ${
      tier.popular 
        ? 'border-2 border-blue-500'
        : isDarkMode ? 'border border-gray-700' : 'border border-gray-200'
    } ${
      isDarkMode ? 'bg-gray-800' : 'bg-white'
    } p-8 shadow-lg flex flex-col`}>
      {tier.popular && (
        <div className="absolute -top-5 left-1/2 transform -translate-x-1/2">
          <span className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
            Best Value
          </span>
        </div>
      )}

      <div className="mb-6">
        <div className="text-3xl font-bold text-blue-500">
          {tier.credits.toLocaleString()}
        </div>
        <div className={`text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          credits
        </div>
      </div>

      <div className="mb-6">
        <div className="text-3xl font-bold">
          ${tier.price}
        </div>
        <div className={`text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          one-time purchase
        </div>
        <div className="text-sm text-blue-500 mt-1">
          ${pricePerCredit} per credit
        </div>
      </div>

      <div className="space-y-3 mb-8 flex-grow">
        <div className="flex items-start">
          <Check className={`w-5 h-5 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-500'
          } mr-2 flex-shrink-0`} />
          <span className={`text-sm ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Never expires
          </span>
        </div>
        <div className="flex items-start">
          <Check className={`w-5 h-5 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-500'
          } mr-2 flex-shrink-0`} />
          <span className={`text-sm ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Use anytime
          </span>
        </div>
        <div className="flex items-start">
          <Check className={`w-5 h-5 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-500'
          } mr-2 flex-shrink-0`} />
          <span className={`text-sm ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            All widget types
          </span>
        </div>
      </div>

      <Link
        to="/register"
        className={`w-full text-center py-3 px-4 rounded-lg font-medium ${
          tier.popular
            ? 'bg-blue-600 text-white hover:bg-blue-700'
            : isDarkMode
              ? 'bg-gray-700 text-white hover:bg-gray-600'
              : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
        } transition-colors`}
      >
        Purchase Credits
      </Link>
    </div>
  );
};

export const PricingPlans: React.FC<PricingPlansProps> = ({
  isAnnual,
  showSubscriptions,
  subscriptions,
  payAsYouGo
}) => {
  const { isDarkMode } = useTheme();

  return (
    <div className="max-w-7xl mx-auto">
      {showSubscriptions ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {subscriptions.map((plan, index) => (
            <SubscriptionCard
              key={index}
              plan={plan}
              isAnnual={isAnnual}
            />
          ))}
        </div>
      ) : (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {payAsYouGo.map((tier, index) => (
            <PayAsYouGoCard
              key={index}
              tier={tier}
            />
          ))}
        </div>
      )}

      <div className={`mt-12 p-6 rounded-xl ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-lg`}>
        <div className="flex items-start space-x-4">
          <div className="bg-blue-100 rounded-lg p-2">
            <Package className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <h3 className={`text-lg font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Need a custom plan?
            </h3>
            <p className={`mt-1 text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              We offer custom plans for trading firms and enterprises with specific needs.
              Contact our sales team to learn more.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center mt-3 text-sm font-medium text-blue-500 hover:text-blue-600"
            >
              Contact Sales
              <Users className="w-4 h-4 ml-1" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
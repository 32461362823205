// src/components/widgets/PromptSuggestions.tsx

import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Sparkles } from 'lucide-react';

interface PromptSuggestionsProps {
  onSelect: (prompt: string) => void;
}

const suggestions = [
  {
    title: 'Real-time Stock Chart',
    prompt: 'Create a real-time stock chart with candlesticks, volume, and basic technical indicators.',
  },
  {
    title: 'Market Overview',
    prompt: 'Create a market overview dashboard showing multiple stocks with price changes and mini charts.',
  },
  {
    title: 'Technical Analysis',
    prompt: 'Create a technical analysis chart with RSI, MACD, and moving averages.',
  },
  {
    title: 'Volume Profile',
    prompt: 'Create a volume profile chart with price levels and POC indicator.',
  },
];

export const PromptSuggestions: React.FC<PromptSuggestionsProps> = ({ onSelect }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {suggestions.map((suggestion, index) => (
        <button
          key={index}
          onClick={() => onSelect(suggestion.prompt)}
          className={`p-4 rounded-lg text-left transition-all ${
            isDarkMode
              ? 'bg-gray-800 hover:bg-gray-700'
              : 'bg-white hover:bg-gray-50'
          } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
        >
          <div className="flex items-center space-x-2">
            <Sparkles className="w-4 h-4 text-blue-500" />
            <span className={`font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {suggestion.title}
            </span>
          </div>
          <p className={`mt-2 text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {suggestion.prompt}
          </p>
        </button>
      ))}
    </div>
  );
};

export default PromptSuggestions;
// src/services/socialAuthService.ts

import { SocialProvider, SocialAuthCredentials, SocialAuthResponse } from '../types/auth';

interface OAuthConfig {
  clientId: string;
  redirectUri: string;
  scope: string;
  authUrl: string;
}

const configs: Record<SocialProvider, OAuthConfig> = {
  github: {
    clientId: process.env.REACT_APP_GITHUB_CLIENT_ID || '',
    redirectUri: `${window.location.origin}/auth/github/callback`,
    scope: 'user:email',
    authUrl: 'https://github.com/login/oauth/authorize'
  },
  linkedin: {
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
    redirectUri: `${window.location.origin}/auth/linkedin/callback`,
    scope: 'r_liteprofile r_emailaddress',
    authUrl: 'https://www.linkedin.com/oauth/v2/authorization'
  }
};

class SocialAuthService {
  private static instance: SocialAuthService;

  private constructor() {}

  static getInstance(): SocialAuthService {
    if (!SocialAuthService.instance) {
      SocialAuthService.instance = new SocialAuthService();
    }
    return SocialAuthService.instance;
  }

  async initiateOAuth(provider: SocialProvider): Promise<void> {
    const config = configs[provider];
    const state = this.generateState();
    localStorage.setItem('oauth_state', state);

    const params = new URLSearchParams({
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      scope: config.scope,
      response_type: 'code',
      state
    });

    window.location.href = `${config.authUrl}?${params.toString()}`;
  }

  async validateCallback(credentials: SocialAuthCredentials): Promise<SocialAuthCredentials> {
    const savedState = localStorage.getItem('oauth_state');
    if (!credentials.state || !savedState || credentials.state !== savedState) {
      throw new Error('Invalid OAuth state');
    }

    localStorage.removeItem('oauth_state');
    return credentials;
  }

  private generateState(): string {
    return Math.random().toString(36).substring(2, 15);
  }
}

export const socialAuthService = SocialAuthService.getInstance();
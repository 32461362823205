// src/pages/docs/tutorials.tsx
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../../components/ui/card';
import { Play, Clock, Book, Video, ChevronRight, Filter, Search } from 'lucide-react';

interface Tutorial {
  id: string;
  title: string;
  description: string;
  duration: string;
  level: 'Beginner' | 'Intermediate' | 'Advanced';
  type: 'Video' | 'Article';
  category: string;
  author: {
    name: string;
    avatar: string;
  };
}

const tutorials: Tutorial[] = [
  {
    id: '1',
    title: 'Getting Started with Widget Creation',
    description: 'Learn the basics of creating your first AI-powered trading widget',
    duration: '15 min',
    level: 'Beginner',
    type: 'Video',
    category: 'Fundamentals',
    author: {
      name: 'Sarah Chen',
      avatar: '/team/sarah-chen.jpg'
    }
  },
  {
    id: '2',
    title: 'Advanced Chart Customization',
    description: 'Master the art of creating sophisticated chart widgets',
    duration: '25 min',
    level: 'Advanced',
    type: 'Video',
    category: 'Charts',
    author: {
      name: 'Michael Rodriguez',
      avatar: '/team/michael-rodriguez.jpg'
    }
  },
  // Add more tutorials...
];

const categories = [
  'All',
  'Fundamentals',
  'Charts',
  'Data Integration',
  'AI Features',
  'Customization',
];

const Tutorials = () => {
  const { isDarkMode } = useTheme();
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [searchQuery, setSearchQuery] = React.useState('');

  const filteredTutorials = tutorials.filter(tutorial => {
    const matchesCategory = selectedCategory === 'All' || tutorial.category === selectedCategory;
    const matchesSearch = tutorial.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         tutorial.description.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Book className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Tutorials & Guides
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Learn how to create powerful trading widgets with our comprehensive tutorials
              </p>
            </div>
          </div>
        </div>

        {/* Filters and Search */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-8">
            {/* Category Filter */}
            <div className="flex items-center space-x-4 overflow-x-auto pb-2 w-full md:w-auto">
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-4 py-2 rounded-lg whitespace-nowrap transition-colors ${
                    selectedCategory === category
                      ? 'bg-blue-600 text-white'
                      : isDarkMode
                        ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                        : 'bg-white text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>

            {/* Search */}
            <div className="relative w-full md:w-72">
              <input
                type="text"
                placeholder="Search tutorials..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`w-full px-4 py-2 pl-10 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-400'
                    : 'bg-white border-gray-200 text-gray-900 placeholder-gray-500'
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
              />
              <Search className={`absolute left-3 top-2.5 w-4 h-4 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
            </div>
          </div>

          {/* Tutorials Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredTutorials.map((tutorial) => (
              <Card
                key={tutorial.id}
                className={`group hover:shadow-lg transition-shadow ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
                }`}
              >
                <CardHeader className="relative">
                  <div className={`absolute top-4 right-4 px-2 py-1 rounded text-xs font-medium ${
                    tutorial.level === 'Beginner'
                      ? 'bg-green-100 text-green-700'
                      : tutorial.level === 'Intermediate'
                        ? 'bg-yellow-100 text-yellow-700'
                        : 'bg-red-100 text-red-700'
                  }`}>
                    {tutorial.level}
                  </div>
                  <div className={`flex items-center text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  } mb-4`}>
                    <div className={`px-2 py-1 rounded ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      {tutorial.category}
                    </div>
                  </div>
                  <CardTitle className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                    {tutorial.title}
                  </CardTitle>
                  <CardDescription className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    {tutorial.description}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      {tutorial.type === 'Video' ? (
                        <Video className="w-4 h-4 text-blue-500" />
                      ) : (
                        <Book className="w-4 h-4 text-blue-500" />
                      )}
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {tutorial.type}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Clock className="w-4 h-4 text-gray-400" />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {tutorial.duration}
                      </span>
                    </div>
                  </div>
                  <button className={`w-full mt-4 flex items-center justify-center px-4 py-2 rounded-lg transition-colors ${
                    isDarkMode
                      ? 'bg-gray-700 text-white hover:bg-gray-600'
                      : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                  }`}>
                    Start Tutorial
                    <ChevronRight className="w-4 h-4 ml-2" />
                  </button>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    
  );
};

export default Tutorials;
// client/src/pages/dashboard/Credits.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import { ErrorAlert } from '../../components/common/ErrorAlert';
import { creditService } from '../../services/creditService';
import { CREDIT_PACKAGES, CreditTransaction } from '../../types/credits';
import { Coins, CreditCard, Gift } from 'lucide-react';

export const Credits: React.FC = () => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [credits, setCredits] = useState<number>(0);
  const [transactions, setTransactions] = useState<CreditTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    loadCreditsAndTransactions();
  }, []);

  const loadCreditsAndTransactions = async () => {
    setIsLoading(true);
    try {
      const [currentCredits, history] = await Promise.all([
        creditService.getCurrentCredits(),
        creditService.getTransactionHistory()
      ]);
      setCredits(currentCredits);
      setTransactions(history);
    } catch (err) {
      setError('Failed to load credit information');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePurchase = async (packageId: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await creditService.purchaseCredits(packageId);
      await loadCreditsAndTransactions();
    } catch (err) {
      setError('Failed to purchase credits');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedeemCoupon = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await creditService.redeemCoupon(couponCode);
      await loadCreditsAndTransactions();
      setCouponCode('');
    } catch (err) {
      setError('Invalid coupon code');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && !credits) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Current Credits Display */}
      <div className={`p-6 rounded-lg mb-8 ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow`}>
        <div className="flex items-center justify-between">
          <div>
            <h2 className={`text-2xl font-bold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Your Credits
            </h2>
            <p className={`text-4xl font-bold mt-2 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`}>
              {credits}
            </p>
          </div>
          <Coins className={`w-16 h-16 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-600'
          }`} />
        </div>
      </div>

      {error && (
        <ErrorAlert message={error} onClose={() => setError(null)} />
      )}

      {/* Credit Packages */}
      <div className="grid md:grid-cols-3 gap-6 mb-8">
        {CREDIT_PACKAGES.map((pkg) => (
          <div
            key={pkg.id}
            className={`p-6 rounded-lg ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            } shadow`}
          >
            <h3 className={`text-xl font-bold mb-2 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {pkg.name}
            </h3>
            <p className={`text-3xl font-bold mb-4 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`}>
              {pkg.credits} Credits
            </p>
            <p className={`mb-4 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              ${pkg.price.toFixed(2)}
            </p>
            <button
              onClick={() => handlePurchase(pkg.id)}
              className="w-full py-2 px-4 rounded bg-blue-600 text-white hover:bg-blue-700 flex items-center justify-center"
              disabled={isLoading}
            >
              <CreditCard className="w-4 h-4 mr-2" />
              Purchase
            </button>
          </div>
        ))}
      </div>

      {/* Coupon Redemption */}
      <div className={`p-6 rounded-lg mb-8 ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow`}>
        <h3 className={`text-xl font-bold mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Redeem Coupon
        </h3>
        <form onSubmit={handleRedeemCoupon} className="flex gap-4">
          <input
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            placeholder="Enter coupon code"
            className={`flex-1 rounded-lg px-4 py-2 ${
              isDarkMode 
                ? 'bg-gray-700 text-white' 
                : 'bg-gray-100 text-gray-900'
            }`}
          />
          <button
            type="submit"
            disabled={isLoading || !couponCode}
            className="py-2 px-4 rounded bg-blue-600 text-white hover:bg-blue-700 flex items-center disabled:opacity-50"
          >
            <Gift className="w-4 h-4 mr-2" />
            Redeem
          </button>
        </form>
      </div>

      {/* Transaction History */}
      <div className={`p-6 rounded-lg ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow`}>
        <h3 className={`text-xl font-bold mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Transaction History
        </h3>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                <th className="text-left py-2">Date</th>
                <th className="text-left py-2">Type</th>
                <th className="text-left py-2">Amount</th>
                <th className="text-left py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr 
                  key={transaction.id}
                  className={`border-t ${
                    isDarkMode 
                      ? 'border-gray-700 text-gray-300' 
                      : 'border-gray-200 text-gray-900'
                  }`}
                >
                  <td className="py-2">
                    {new Date(transaction.createdAt).toLocaleDateString()}
                  </td>
                  <td className="py-2">{transaction.type}</td>
                  <td className="py-2">{transaction.amount}</td>
                  <td className="py-2">{transaction.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
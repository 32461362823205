// src/pages/dashboard/WidgetCustomization.tsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useWidget } from '../../hooks/useWidget';
import { WidgetCustomizationPanel } from '../../components/widgets/WidgetCustomizationPanel';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import { ErrorAlert } from '../../components/common/ErrorAlert';
import { claudeService } from '../../services/claudeService';
import { AIMessage } from '../../types/shared';

export const WidgetCustomization: React.FC = () => {
  const { widgetId } = useParams<{ widgetId: string }>();
  const navigate = useNavigate();
  const { widget, updateWidget, isLoading, error } = useWidget(widgetId || '');

  const handleAIRefine = async (instruction: string, context: AIMessage[]) => {
    try {
      const response = await claudeService.generateWidgetRefinement(
        widget!,
        instruction,
        context
      );
      return response;
    } catch (error) {
      console.error('AI refinement failed:', error);
      throw error;
    }
  };

  const handleSave = async (updatedConfig: any) => {
    try {
      await updateWidget(widgetId!, {
        config: updatedConfig
      });
      navigate('/dashboard/library');
    } catch (error) {
      console.error('Failed to save widget:', error);
      throw error;
    }
  };

  if (isLoading || !widget) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorAlert message={error} />;
  }

  return (
    <WidgetCustomizationPanel
      widget={widget}
      onSave={handleSave}
      onAIRefine={handleAIRefine}
      className="h-full"
    />
  );
};

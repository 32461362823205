import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Loader } from 'lucide-react';

interface LoadingSpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  message?: string;
  progress?: number;
  className?: string;
  fullScreen?: boolean;
}

const sizeMap = {
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
  lg: 'w-8 h-8'
};

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'md',
  message,
  progress,
  className = '',
  fullScreen = false
}) => {
  const { isDarkMode } = useTheme();

  const content = (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <Loader 
        className={`${sizeMap[size]} animate-spin text-blue-500`} 
      />
      {message && (
        <div className={`mt-2 text-sm ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          {message}
        </div>
      )}
      {typeof progress === 'number' && (
        <div className="mt-2 w-48">
          <div className="h-1 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-blue-500 transition-all duration-300 rounded-full"
              style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
            />
          </div>
          <div className="mt-1 text-xs text-center text-gray-500">
            {Math.round(progress)}%
          </div>
        </div>
      )}
    </div>
  );

  if (fullScreen) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black/20 dark:bg-black/40 backdrop-blur-sm z-50">
        {content}
      </div>
    );
  }

  return content;
};

export default LoadingSpinner;
// client/src/pages/Features.tsx
import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { MainLayout } from '../components/layout/MainLayout';
import { LucideIcon } from 'lucide-react';
import {
  LineChart,
  BarChart3,
  RefreshCcw,
  Boxes,
  BrainCircuit,
  ScrollText,
  Gauge,
  Bell,
  Laptop,
  Wrench
} from 'lucide-react';

interface FeatureSectionProps {
  icon: LucideIcon;
  title: string;
  description: string;
  isNew?: boolean;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({ 
  icon: Icon, 
  title, 
  description, 
  isNew = false 
}) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`p-6 rounded-xl ${
      isDarkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-50'
    } shadow-lg transition-all duration-300 group relative`}>
      <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-xl opacity-0 group-hover:opacity-10 transition duration-200" />
      <div className="flex items-start space-x-4">
        <div className="bg-blue-600 rounded-lg p-3 text-white">
          <Icon className="w-6 h-6" />
        </div>
        <div>
          <div className="flex items-center gap-2">
            <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {title}
            </h3>
            {isNew && (
              <span className="px-2 py-1 text-xs font-medium text-white bg-blue-600 rounded-full">
                New
              </span>
            )}
          </div>
          <p className={`mt-2 text-base ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const { isDarkMode } = useTheme();

  const features = [
    {
      icon: BrainCircuit,
      title: 'AI-Powered Chart Generation',
      description: 'Simply describe your desired trading visualization in plain English, and watch as AI creates a professional-grade technical analysis chart.',
      isNew: true
    },
    {
      icon: LineChart,
      title: 'Advanced Technical Analysis',
      description: 'Built-in support for over 50+ technical indicators, including custom Moving Averages, RSI, MACD, Bollinger Bands, and Fibonacci retracements.',
    },
    {
      icon: RefreshCcw,
      title: 'Real-time Market Data',
      description: 'Live integration with major exchanges and data providers for real-time price updates, order book depth, and trade flow analysis.',
    },
    {
      icon: BarChart3,
      title: 'Multi-Asset Visualization',
      description: 'Create complex multi-instrument charts with correlation analysis, spread trading views, and custom overlays.',
    },
    {
      icon: Boxes,
      title: 'Market Scanner Widgets',
      description: 'Build custom market scanners with multiple criteria, real-time filtering, and alert systems for trading opportunities.',
    },
    {
      icon: ScrollText,
      title: 'Order Flow Analysis',
      description: 'Visualize trading volume, market depth, and order flow patterns with customizable heatmaps and volume profile analysis.',
    },
    {
      icon: Gauge,
      title: 'Performance Analytics',
      description: 'Track and analyze trading performance with P&L charts, drawdown metrics, and risk analytics widgets.',
    },
    {
      icon: Bell,
      title: 'Smart Alerts',
      description: 'Create intelligent price, volume, and technical indicator alerts with custom notification systems.',
    },
    {
      icon: Laptop,
      title: 'Cross-Platform Integration',
      description: 'Seamlessly embed widgets into any trading platform, website, or application with our flexible API.',
    },
    {
      icon: Wrench,
      title: 'Custom Indicators',
      description: 'Develop and deploy custom technical indicators and oscillators using our intuitive widget creation system.',
    }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Hero Section */}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-blue-800 opacity-10" />
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-24 relative">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className={`text-4xl sm:text-5xl font-bold tracking-tight ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            } mb-6`}>
              Professional Trading Widgets <br />Powered by AI
            </h1>
            <p className={`text-xl ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            } mb-8`}>
              Transform complex market data into actionable insights with AI-generated 
              trading widgets and advanced technical analysis tools.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                <LineChart className="w-5 h-5 mr-2" />
                Start Trading Free
              </Link>
              <Link
                to="/docs"
                className={`inline-flex items-center px-6 py-3 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-800 text-white hover:bg-gray-700' 
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                } transition-colors`}
              >
                <ScrollText className="w-5 h-5 mr-2" />
                View Documentation
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureSection key={index} {...feature} />
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-24`}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className={`text-3xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Ready to Transform Your Trading?
            </h2>
            <p className={`text-lg mb-8 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Join professional traders and institutions already using Newsroom to create 
              powerful, data-driven trading widgets.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/register"
                className="inline-flex items-center px-8 py-4 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors text-lg"
              >
                Start Building Now
              </Link>
              <Link
                to="/contact"
                className={`inline-flex items-center px-8 py-4 rounded-lg ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                } transition-colors text-lg`}
              >
                Contact Sales
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// services/marketDataService.ts

import { 
    DataTap, 
    DataPoint, 
    DataTapCreationParams,
    DataTapUpdateParams,
    DataFrequency,
    DataProvider,
    DataSubscription,
    MarketDataError
  } from '../types/marketData';
  import api from './api';
  
  class MarketDataService {
    private static instance: MarketDataService;
    private dataTaps: Map<string, DataTap> = new Map();
    private subscriptions: Map<string, Set<DataSubscription>> = new Map();
    private pollingIntervals: Map<string, NodeJS.Timeout> = new Map();
    
    private constructor() {}
  
    static getInstance(): MarketDataService {
      if (!MarketDataService.instance) {
        MarketDataService.instance = new MarketDataService();
      }
      return MarketDataService.instance;
    }
  
    async createDataTap(params: DataTapCreationParams): Promise<DataTap> {
      try {
        const { data } = await api.post<DataTap>('/market-data/taps', params);
        this.dataTaps.set(data.id, data);
        this.setupPolling(data);
        return data;
      } catch (error) {
        throw this.handleError(error);
      }
    }
  
    async updateDataTap(id: string, params: DataTapUpdateParams): Promise<DataTap> {
      try {
        const { data } = await api.put<DataTap>(`/market-data/taps/${id}`, params);
        this.dataTaps.set(data.id, data);
        
        // Update polling if frequency changed
        if (params.frequency) {
          this.resetPolling(data);
        }
        
        return data;
      } catch (error) {
        throw this.handleError(error);
      }
    }
  
    async deleteDataTap(id: string): Promise<void> {
      try {
        await api.delete(`/market-data/taps/${id}`);
        this.dataTaps.delete(id);
        this.clearPolling(id);
        this.subscriptions.delete(id);
      } catch (error) {
        throw this.handleError(error);
      }
    }
  
    async getDataTaps(): Promise<DataTap[]> {
      try {
        const { data } = await api.get<DataTap[]>('/market-data/taps');
        data.forEach(tap => {
          this.dataTaps.set(tap.id, tap);
          this.setupPolling(tap);
        });
        return data;
      } catch (error) {
        throw this.handleError(error);
      }
    }
  
    subscribe(tapId: string, subscription: DataSubscription): () => void {
      if (!this.subscriptions.has(tapId)) {
        this.subscriptions.set(tapId, new Set());
      }
      this.subscriptions.get(tapId)?.add(subscription);
      
      return () => {
        this.subscriptions.get(tapId)?.delete(subscription);
        if (this.subscriptions.get(tapId)?.size === 0) {
          this.subscriptions.delete(tapId);
        }
      };
    }
  
    private async fetchData(tap: DataTap): Promise<DataPoint[]> {
      try {
        const { data } = await api.get<DataPoint[]>(`/market-data/${tap.id}/data`, {
          params: {
            from: this.getLastUpdateTime(tap.frequency),
            to: new Date().toISOString()
          }
        });
        return data;
      } catch (error) {
        throw this.handleError(error);
      }
    }
  
    private setupPolling(tap: DataTap): void {
      if (!tap.isActive) return;
      
      const interval = this.getPollingInterval(tap.frequency);
      const polling = setInterval(async () => {
        try {
          const data = await this.fetchData(tap);
          this.notifySubscribers(tap.id, data);
        } catch (error) {
          this.handlePollingError(tap.id, error);
        }
      }, interval);
  
      this.pollingIntervals.set(tap.id, polling);
    }
  
    private resetPolling(tap: DataTap): void {
      this.clearPolling(tap.id);
      this.setupPolling(tap);
    }
  
    private clearPolling(tapId: string): void {
      const interval = this.pollingIntervals.get(tapId);
      if (interval) {
        clearInterval(interval);
        this.pollingIntervals.delete(tapId);
      }
    }
  
    private notifySubscribers(tapId: string, data: DataPoint[]): void {
      this.subscriptions.get(tapId)?.forEach(subscription => {
        try {
          subscription.callback(data);
        } catch (error) {
          subscription.errorHandler?.(error as Error);
        }
      });
    }
  
    private handlePollingError(tapId: string, error: unknown): void {
      const tap = this.dataTaps.get(tapId);
      if (!tap) return;
  
      tap.errorCount = (tap.errorCount || 0) + 1;
      if (tap.errorCount >= 3) {
        tap.isActive = false;
        this.clearPolling(tapId);
        this.updateDataTap(tapId, { isActive: false }).catch(console.error);
      }
    }
  
    private getPollingInterval(frequency: DataFrequency): number {
      const intervals: Record<DataFrequency, number> = {
        '1m': 60000,
        '5m': 300000,
        '15m': 900000,
        '1h': 3600000,
        '1d': 86400000,
        '1w': 604800000
      };
      return intervals[frequency];
    }
  
    private getLastUpdateTime(frequency: DataFrequency): string {
      const now = new Date();
      const offsets: Record<DataFrequency, number> = {
        '1m': 60,
        '5m': 300,
        '15m': 900,
        '1h': 3600,
        '1d': 86400,
        '1w': 604800
      };
      now.setSeconds(now.getSeconds() - offsets[frequency]);
      return now.toISOString();
    }
  
    private handleError(error: unknown): MarketDataError {
      if (error instanceof Error) {
        return {
          ...error,
          code: 'MARKET_DATA_ERROR',
          retryable: true
        };
      }
      return {
        name: 'MarketDataError',
        message: 'An unknown error occurred',
        code: 'UNKNOWN_ERROR',
        retryable: false
      };
    }
  }
  
  export const marketDataService = MarketDataService.getInstance();
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

import {
  Users,
  Code,
  TrendingUp,
  HeartHandshake,
  GraduationCap,
  Globe,
  Briefcase,
  MapPin,
  DollarSign,
  Clock,
  ArrowRight
} from 'lucide-react';

interface JobPosting {
  id: string;
  title: string;
  department: string;
  location: string;
  type: 'Full-time' | 'Part-time' | 'Contract';
  experience: string;
  salary: string;
}

const openings: JobPosting[] = [
  {
    id: '1',
    title: 'Senior Frontend Engineer',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    experience: '5+ years',
    salary: '$120k - $180k'
  },
  {
    id: '2',
    title: 'Machine Learning Engineer',
    department: 'AI',
    location: 'Remote',
    type: 'Full-time',
    experience: '3+ years',
    salary: '$130k - $190k'
  },
  {
    id: '3',
    title: 'Product Designer',
    department: 'Design',
    location: 'Remote',
    type: 'Full-time',
    experience: '4+ years',
    salary: '$100k - $160k'
  },
  {
    id: '4',
    title: 'Technical Writer',
    department: 'Documentation',
    location: 'Remote',
    type: 'Full-time',
    experience: '2+ years',
    salary: '$80k - $120k'
  }
];

const values = [
  {
    icon: HeartHandshake,
    title: 'User-First',
    description: 'We build for our users, always prioritizing their needs and success.'
  },
  {
    icon: GraduationCap,
    title: 'Continuous Learning',
    description: 'We embrace growth and learning, supporting each others development.'
  },
  {
    icon: Globe,
    title: 'Remote-First',
    description: 'Work from anywhere. We believe in flexibility and trust.'
  },
  {
    icon: TrendingUp,
    title: 'Impact-Driven',
    description: 'Every team member contributes directly to our mission and growth.'
  }
];

const benefits = [
  'Competitive salary and equity',
  'Remote-first culture',
  'Flexible working hours',
  'Health, dental, and vision insurance',
  'Unlimited PTO',
  'Learning and development budget',
  'Home office stipend',
  'Regular team retreats'
];

const Careers = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Users className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Join Our Team
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Help us transform the future of trading technology. We're looking for 
              passionate individuals to join our remote-first team.
            </p>
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-12 text-center ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Our Values
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {values.map((value, index) => {
            const Icon = value.icon;
            return (
              <div key={index} className={`p-6 rounded-lg border ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700' 
                  : 'bg-white border-gray-200'
              }`}>
                <div className="w-12 h-12 rounded-lg bg-blue-600 flex items-center justify-center mb-4">
                  <Icon className="w-6 h-6 text-white" />
                </div>
                <h3 className={`text-lg font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {value.title}
                </h3>
                <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                  {value.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Benefits Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-2xl font-bold mb-12 text-center ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Benefits & Perks
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-900 border-gray-700' 
                    : 'bg-gray-50 border-gray-200'
                }`}
              >
                <div className={`text-base ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {benefit}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Open Positions */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h2 className={`text-2xl font-bold mb-12 text-center ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Open Positions
        </h2>
        <div className="space-y-4">
          {openings.map((job) => (
            <div
              key={job.id}
              className={`p-6 rounded-lg border transition-colors ${
                isDarkMode 
                  ? 'bg-gray-800 border-gray-700 hover:border-blue-500' 
                  : 'bg-white border-gray-200 hover:border-blue-500'
              }`}
            >
              <div className="flex flex-col md:flex-row md:items-center justify-between">
                <div className="mb-4 md:mb-0">
                  <h3 className={`text-lg font-bold mb-2 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {job.title}
                  </h3>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex items-center">
                      <Briefcase className="w-4 h-4 mr-1 text-blue-500" />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {job.department}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="w-4 h-4 mr-1 text-green-500" />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {job.location}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-1 text-purple-500" />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {job.type}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <DollarSign className="w-4 h-4 mr-1 text-yellow-500" />
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {job.salary}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                >
                  Apply Now
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Section */}
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} py-16`}>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className={`text-2xl font-bold mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Don't see the right role?
          </h2>
          <p className={`text-lg mb-8 ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            We're always looking for talented individuals to join our team. Send us your resume
            and we'll keep you in mind for future opportunities.
          </p>
          <a
            href="mailto:careers@news-room.ca"
            className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          >
            Get in Touch
            <ArrowRight className="w-5 h-5 ml-2" />
          </a>
        </div>
      </div>

      
    </div>
  );
};

export default Careers;
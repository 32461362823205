import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';


import { 
  Code,
  Terminal,
  Server,
  Key,
  Send,
  Database,
  Shield,
  Clock
} from 'lucide-react';

const ApiDocumentation = () => {
  const { isDarkMode } = useTheme();

  const endpoints = [
    {
      title: 'Authentication',
      description: 'Secure API access with JWT tokens',
      icon: Key,
      method: 'POST',
      path: '/api/auth'
    },
    {
      title: 'Widgets',
      description: 'Create and manage trading widgets',
      icon: Server,
      method: 'POST',
      path: '/api/widgets'
    },
    {
      title: 'Market Data',
      description: 'Real-time market data streams',
      icon: Database,
      method: 'GET',
      path: '/api/market-data'
    },
    {
      title: 'Claude Integration',
      description: 'AI-powered widget generation',
      icon: Terminal,
      method: 'POST',
      path: '/api/claude'
    }
  ];

  return (
    <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
      
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
          <div className="text-center">
            <Code className={`w-12 h-12 mx-auto mb-6 ${
              isDarkMode ? 'text-blue-400' : 'text-blue-600'
            }`} />
            <h1 className={`text-4xl font-bold mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              API Documentation
            </h1>
            <p className={`text-xl max-w-3xl mx-auto ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              Build custom trading applications with our powerful and flexible APIs.
              Access real-time market data, generate AI widgets, and more.
            </p>
          </div>
        </div>
      </div>

      {/* API Overview */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {endpoints.map((endpoint, index) => {
            const Icon = endpoint.icon;
            return (
              <div 
                key={index}
                className={`p-6 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-gray-800 border-gray-700' 
                    : 'bg-white border-gray-200'
                }`}
              >
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-2 bg-blue-600 rounded-lg">
                    <Icon className="w-6 h-6 text-white" />
                  </div>
                  <div>
                    <h3 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {endpoint.title}
                    </h3>
                    <span className={`text-sm ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`}>
                      {endpoint.method}
                    </span>
                  </div>
                </div>
                <p className={`text-sm mb-4 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {endpoint.description}
                </p>
                <code className={`block px-3 py-2 rounded text-sm font-mono ${
                  isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
                }`}>
                  {endpoint.path}
                </code>
              </div>
            );
          })}
        </div>
      </div>

      {/* Getting Started */}
      <div className={`${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } py-16`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-2xl font-bold mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Getting Started
          </h2>
          <div className="prose max-w-none">
            <pre className={`p-4 rounded-lg ${
              isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
            }`}>
              <code className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
{`// Install our client library
npm install @newsroom/api-client

// Initialize the client
import { NewsroomAPI } from '@newsroom/api-client';

const api = new NewsroomAPI({
  apiKey: 'your-api-key',
  environment: 'production'
});

// Create a widget
const widget = await api.widgets.create({
  name: 'My Trading Widget',
  type: 'CHART',
  config: {
    // widget configuration
  }
});`}
              </code>
            </pre>
          </div>
        </div>
      </div>

      {/* Rate Limits & Authentication */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <div className="flex items-center space-x-3 mb-6">
              <Clock className={`w-6 h-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h2 className={`text-2xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Rate Limits
              </h2>
            </div>
            <div className={`p-6 rounded-lg border ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <table className="w-full">
                <thead>
                  <tr className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                    <th className="text-left py-2">Plan</th>
                    <th className="text-right py-2">Requests/min</th>
                  </tr>
                </thead>
                <tbody className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
                  <tr>
                    <td className="py-2">Free</td>
                    <td className="text-right">60</td>
                  </tr>
                  <tr>
                    <td className="py-2">Pro</td>
                    <td className="text-right">300</td>
                  </tr>
                  <tr>
                    <td className="py-2">Enterprise</td>
                    <td className="text-right">Unlimited</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div className="flex items-center space-x-3 mb-6">
              <Shield className={`w-6 h-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h2 className={`text-2xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Authentication
              </h2>
            </div>
            <div className={`p-6 rounded-lg border ${
              isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
            }`}>
              <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                All API requests require authentication using JWT tokens. Include your API
                key in the Authorization header:
              </p>
              <pre className={`p-4 rounded ${
                isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
              }`}>
                <code className={isDarkMode ? 'text-gray-300' : 'text-gray-800'}>
                  Authorization: Bearer your-api-key
                </code>
              </pre>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default ApiDocumentation;
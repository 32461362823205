import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { newsletterService } from '../services/newsletterService';
import { useTheme } from '../contexts/ThemeContext';
import { 
  Twitter, 
  Linkedin, 
  Github, 
  Mail,
  ArrowUpRight,
  LucideIcon
} from 'lucide-react';



interface FooterLink {
  name: string;
  href: string;
}

interface FooterSection {
  title: string;
  links: FooterLink[];
}

interface SocialLink {
  name: string;
  icon: LucideIcon;
  href: string;
}

interface FooterData {
  product: FooterSection;
  company: FooterSection;
  resources: FooterSection;
  legal: FooterSection;
  social: SocialLink[];
}



const footerData: FooterData = {
  product: {
    title: 'Product',
    links: [
      { name: 'Features', href: '/features' },
      { name: 'Pricing', href: '/pricing' },
      { name: 'API', href: '/docs/api' },
      { name: 'Documentation', href: '/docs' },
      { name: 'Release Notes', href: '/docs/releases' },
    ]
  },
  company: {
    title: 'Company',
    links: [
      { name: 'About', href: '/about' },
      { name: 'Careers', href: '/careers' },
      { name: 'Press', href: '/press' },
      { name: 'Contact', href: '/contact' },
      { name: 'Partners', href: '/partners' },
    ]
  },
  resources: {
    title: 'Resources',
    links: [
      { name: 'Blog', href: '/blog' },
      { name: 'Newsletter', href: '/newsletter' },
      { name: 'Events', href: '/events' },
      { name: 'Help Center', href: '/help' },
      { name: 'Tutorials', href: '/docs/tutorials' },
    ]
  },
  legal: {
    title: 'Legal',
    links: [
      { name: 'Privacy', href: '/privacy' },
      { name: 'Terms', href: '/terms' },
      { name: 'Security', href: '/security' },
      { name: 'Cookies', href: '/cookies' },
    ]
  },
  social: [
    { name: 'Twitter', icon: Twitter, href: 'https://x.com/getnewsroom' },
    { name: 'LinkedIn', icon: Linkedin, href: 'https://www.linkedin.com/company/getnewsroom' },
    { name: 'GitHub', icon: Github, href: 'https://github.com/newsroom-oauth' },
    { name: 'Email', icon: Mail, href: 'mailto:help@news-room.ca' },
  ]
};

// Create a type for the footer sections without social
type FooterSectionKeys = Exclude<keyof FooterData, 'social'>;

// Helper function to check if a key is a footer section
const isFooterSection = (key: string): key is FooterSectionKeys => {
  return ['product', 'company', 'resources', 'legal'].includes(key);
};

export const Footer: React.FC = () => {
  const { isDarkMode } = useTheme();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleNewsletterSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(false);
  
    try {
      await newsletterService.subscribe(email);
      setSuccess(true);
      setEmail('');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to subscribe to newsletter');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className={`${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} border-t ${
      isDarkMode ? 'border-gray-800' : 'border-gray-200'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main footer content */}
        <div className="py-12 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8">
          {/* Newsletter Section */}
          <div className="col-span-2 lg:col-span-2">
            <img
              src={isDarkMode ? '/logo-dark.png' : '/logo-light.png'}
              alt="Newsroom"
              className="h-8 mb-6"
            />
            <h3 className={`text-sm font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            } mb-4`}>
              Subscribe to our newsletter
            </h3>
            <p className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            } mb-4`}>
              Get the latest news and updates from our team
            </p>
            <form onSubmit={handleNewsletterSubmit} className="flex gap-2">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          disabled={isSubmitting}
          className={`flex-1 rounded-lg px-4 py-2 text-sm ${
            isDarkMode 
              ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-500'
              : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
          } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 rounded-lg bg-blue-600 text-white text-sm font-medium 
          hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
          focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
                      {error && (
              <p className="mt-2 text-sm text-red-500">{error}</p>
            )}

            {success && (
              <p className="mt-2 text-sm text-green-500">
                Thank you for subscribing to our newsletter!
              </p>
            )}
          </div>

          {/* Dynamic Link Sections */}
          {Object.entries(footerData)
            .filter(([key]) => isFooterSection(key))
            .map(([key, section]) => {
              // Type assertion is safe here because we filtered with isFooterSection
              const typedSection = section as FooterSection;
              return (
                <div key={key}>
                  <h3 className={`text-sm font-semibold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  } mb-4`}>
                    {typedSection.title}
                  </h3>
                  <ul className="space-y-3">
                    {typedSection.links.map((link: FooterLink) => (
                      <li key={link.name}>
                        <Link
                          to={link.href}
                          className={`text-sm ${
                            isDarkMode 
                              ? 'text-gray-400 hover:text-white' 
                              : 'text-gray-600 hover:text-gray-900'
                          } flex items-center`}
                        >
                          {link.name}
                          {link.href.startsWith('http') && (
                            <ArrowUpRight className="ml-1 h-3 w-3" />
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
        </div>

        {/* Bottom bar */}
        <div className={`py-6 border-t ${
          isDarkMode ? 'border-gray-800' : 'border-gray-200'
        } flex flex-col sm:flex-row justify-between items-center gap-4`}>
          <div className={`text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            © {currentYear} Newsroom. All rights reserved.
          </div>

          {/* Social links */}
          <div className="flex space-x-4">
            {footerData.social.map((item) => {
              const Icon = item.icon;
              return (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`p-2 rounded-lg ${
                    isDarkMode
                      ? 'text-gray-400 hover:text-white hover:bg-gray-800'
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                  }`}
                >
                  <span className="sr-only">{item.name}</span>
                  <Icon className="h-5 w-5" />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};
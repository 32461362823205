// components/MarketData.tsx

import React, { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { marketDataService } from '../services/marketDataService';
import { 
  DataTap, 
  DataPoint, 
  DataFrequency,
  DataTapStatus,
  DataTapCreationParams 
} from '../types/marketData';
import { 
  Waves,
  Plus,
  Play,
  Pause,
  Trash2,
  Settings,
  AlertTriangle,
  BarChart4,
  Clock,
  RefreshCcw,
  ChevronRight,
  ArrowUpRight,
  Activity
} from 'lucide-react';
import { LoadingSpinner } from './common/LoadingSpinner';
import { Alert } from './ui/alert';
import { Button } from './ui/button';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter
} from './ui/card';

export const MarketData: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [dataTaps, setDataTaps] = useState<DataTap[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newTapData, setNewTapData] = useState<Partial<DataTapCreationParams>>({
    frequency: '1m'
  });

  useEffect(() => {
    loadDataTaps();
  }, []);

  const loadDataTaps = async () => {
    try {
      const taps = await marketDataService.getDataTaps();
      setDataTaps(taps);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load data taps');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateTap = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTapData.name || !newTapData.symbol) return;

    setIsCreating(true);
    try {
      const tap = await marketDataService.createDataTap(newTapData as DataTapCreationParams);
      setDataTaps(prev => [...prev, tap]);
      setIsCreating(false);
      setNewTapData({ frequency: '1m' });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create data tap');
    }
  };

  const handleUpdateTap = async (id: string, updates: any) => {
    try {
      const updatedTap = await marketDataService.updateDataTap(id, updates);
      setDataTaps(prev => prev.map(tap => tap.id === id ? updatedTap : tap));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update data tap');
    }
  };

  const handleDeleteTap = async (id: string) => {
    try {
      await marketDataService.deleteDataTap(id);
      setDataTaps(prev => prev.filter(tap => tap.id !== id));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete data tap');
    }
  };

  const getStatusColor = (tap: DataTap): string => {
    if (!tap.isActive) return 'text-gray-400';
    if (tap.errorCount && tap.errorCount > 0) return 'text-red-500';
    return 'text-green-500';
  };

  const getLastUpdateText = (tap: DataTap): string => {
    if (!tap.lastUpdated) return 'Never';
    const date = new Date(tap.lastUpdated);
    return date.toLocaleString();
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner />
      </div>
    );
  }

  const inputClasses = `w-full rounded-lg px-4 py-2 ${
    isDarkMode 
      ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-500' 
      : 'bg-white border-gray-200 text-gray-900 placeholder-gray-400'
  } focus:ring-2 focus:ring-blue-500 focus:border-transparent`;

  const labelClasses = `block text-sm font-medium ${
    isDarkMode ? 'text-gray-200' : 'text-gray-700'
  } mb-2`;

  return (
    <div className="space-y-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Enhanced Header */}
      <div className="relative">
      <div className="relative -mx-4 -mt-4 px-4 pt-4 pb-8 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-blue-800/10" />
          <div className="flex items-center justify-between mb-6">
            <div>
              <h1 className={`text-3xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Market Data Hub
              </h1>
              <p className={`mt-2 text-lg ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Manage your real-time market data sources and integrations
              </p>
            </div>
            <Button
              onClick={() => setIsCreating(true)}
              className="flex items-center px-6 py-3 bg-blue-600 hover:bg-blue-700"
            >
              <Plus className="w-5 h-5 mr-2" />
              New Data Tap
            </Button>
          </div>

          {/* Quick Stats */}

          <div className={`mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <h2 className="text-lg font-semibold">Overview</h2>
            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Real-time statistics of your market data sources
            </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
            {[
              { label: 'Active Taps', value: dataTaps.filter(t => t.isActive).length, icon: Activity },
              { label: 'Total Metrics', value: dataTaps.reduce((acc, tap) => acc + tap.metrics.length, 0), icon: BarChart4 },
              { label: 'Update Frequency', value: '1m - 1w', icon: Clock },
              { label: 'Data Points', value: '10K+', icon: Waves }
            ].map((stat, index) => (
              <Card key={index} className={`${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
              }`}>
                <CardContent className="p-6">
                  <div className="flex justify-between items-start mt-4">
                    <div>
                      <p className={`text-sm font-medium ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {stat.label}
                      </p>
                      <p className={`mt-2 text-2xl font-bold ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {stat.value}
                      </p>
                    </div>
                    <div className={`p-3 rounded-lg ${
                      isDarkMode ? 'bg-gray-700' : 'bg-blue-50'
                    }`}>
                      <stat.icon className={`w-5 h-5 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-500'
                      }`} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>

      {error && (
        <Alert variant="destructive">{error}</Alert>
      )}

      {/* Create New Tap Modal/Card */}
      {isCreating && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <Card className={`w-full max-w-2xl ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
          }`}>
            <CardHeader>
              <CardTitle className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                Create New Data Tap
              </CardTitle>
              <CardDescription className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                Configure a new source for real-time market data
              </CardDescription>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleCreateTap} className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className={labelClasses}>Name</label>
                    <input
                      type="text"
                      value={newTapData.name || ''}
                      onChange={e => setNewTapData(prev => ({ ...prev, name: e.target.value }))}
                      className={inputClasses}
                      placeholder="e.g., AAPL Daily Data"
                    />
                  </div>
                  <div>
                    <label className={labelClasses}>Symbol</label>
                    <input
                      type="text"
                      value={newTapData.symbol || ''}
                      onChange={e => setNewTapData(prev => ({ 
                        ...prev, 
                        symbol: e.target.value.toUpperCase() 
                      }))}
                      className={inputClasses}
                      placeholder="e.g., AAPL"
                    />
                  </div>
                </div>

                <div>
                  <label className={labelClasses}>Frequency</label>
                  <select
                    value={newTapData.frequency}
                    onChange={e => setNewTapData(prev => ({ 
                      ...prev, 
                      frequency: e.target.value as DataFrequency 
                    }))}
                    className={inputClasses}
                  >
                    <option value="1m">1 Minute</option>
                    <option value="5m">5 Minutes</option>
                    <option value="15m">15 Minutes</option>
                    <option value="1h">1 Hour</option>
                    <option value="1d">1 Day</option>
                    <option value="1w">1 Week</option>
                  </select>
                </div>

                <div>
                  <label className={labelClasses}>Metrics</label>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {['open', 'high', 'low', 'close', 'volume', 'vwap'].map(metric => (
                      <label 
                        key={metric}
                        className={`flex items-center space-x-3 p-3 rounded-lg border ${
                          isDarkMode ? 'border-gray-700 hover:bg-gray-700' : 'border-gray-200 hover:bg-gray-50'
                        } cursor-pointer transition-colors`}
                      >
                        <input
                          type="checkbox"
                          checked={newTapData.metrics?.includes(metric) || false}
                          onChange={e => {
                            const metrics = newTapData.metrics || [];
                            setNewTapData(prev => ({
                              ...prev,
                              metrics: e.target.checked
                                ? [...metrics, metric]
                                : metrics.filter(m => m !== metric)
                            }));
                          }}
                          className="rounded border-gray-300"
                        />
                        <span className={`capitalize ${
                          isDarkMode ? 'text-gray-200' : 'text-gray-700'
                        }`}>
                          {metric}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </form>
            </CardContent>
            <CardFooter className="flex justify-end space-x-3">
              <Button
                variant="outline"
                onClick={() => setIsCreating(false)}
                className={isDarkMode ? 'text-gray-200 border-gray-600' : ''}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateTap}
                disabled={!newTapData.name || !newTapData.symbol || !newTapData.metrics?.length}
                className="bg-blue-600 hover:bg-blue-700"
              >
                Create Data Tap
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}

      {/* Data Taps Grid */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {dataTaps.map(tap => (
          <Card key={tap.id} className={`group relative ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
          } hover:shadow-lg transition-all duration-300`}>
            {/* Status Indicator */}
            <div className={`absolute top-0 right-0 w-2 h-2 m-4 rounded-full ${
              tap.isActive 
                ? tap.errorCount ? 'bg-red-500' : 'bg-green-500'
                : 'bg-gray-400'
            }`} />
            
            <CardHeader className="pb-3">
              <div className="flex items-start justify-between">
                <div>
                  <CardTitle className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                    {tap.name}
                  </CardTitle>
                  <CardDescription className={`flex items-center ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    {tap.symbol}
                    <ArrowUpRight className="w-4 h-4 ml-1" />
                  </CardDescription>
                </div>
                <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleUpdateTap(tap.id, { isActive: !tap.isActive })}
                    className={isDarkMode ? 'text-gray-400 hover:text-white' : ''}
                  >
                    {tap.isActive ? (
                      <Pause className="w-4 h-4" />
                    ) : (
                      <Play className="w-4 h-4" />
                    )}
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleDeleteTap(tap.id)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </CardHeader>

            <CardContent>
              <div className="space-y-4">
                {/* Metrics */}
                <div className="flex flex-wrap gap-2">
                  {tap.metrics.map(metric => (
                    <span
                      key={metric}
                      className={`px-3 py-1 rounded-full text-xs font-medium ${
                        isDarkMode
                          ? 'bg-gray-700 text-gray-300'
                          : 'bg-gray-100 text-gray-600'
                      }`}
                    >
                      {metric}
                    </span>
                  ))}
                </div>

                {/* Stats */}
                <div className="grid grid-cols-2 gap-4">
                  <div className={`p-3 rounded-lg ${
                    isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                  }`}>
                    <div className={`text-sm ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`}>
                      Frequency
                    </div>
                    <div className={`text-lg font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {tap.frequency}
                    </div>
                  </div>
                  <div className={`p-3 rounded-lg ${
                    isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                  }`}>
                    <div className={`text-sm ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`}>
                      Last Update
                    </div>
                    <div className={`text-lg font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {getLastUpdateText(tap)}
                    </div>
                  </div>
                </div>

                {/* Error Alert */}
                {tap.errorCount && tap.errorCount > 0 && (
                  <div className={`p-3 rounded-lg bg-red-500/10 border border-red-500/20 ${
                    isDarkMode ? 'text-red-400' : 'text-red-600'
                  }`}>
                    <div className="flex items-center">
                      <AlertTriangle className="w-4 h-4 mr-2" />
                      <span className="text-sm font-medium">
                        {tap.errorCount} error{tap.errorCount > 1 ? 's' : ''} occurred
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        ))}
        </div>
  
        {/* Empty State */}
        {dataTaps.length === 0 && !isLoading && (
          <div className={`text-center py-12 rounded-lg border-2 border-dashed ${
            isDarkMode 
              ? 'border-gray-700 bg-gray-800/50' 
              : 'border-gray-200 bg-gray-50'
          }`}>
            <Waves className={`w-12 h-12 mx-auto mb-4 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
            <h3 className={`text-lg font-medium mb-2 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              No Data Taps
            </h3>
            <p className={`text-sm mb-4 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Create your first data tap to start monitoring market data
            </p>
            <Button
              onClick={() => setIsCreating(true)}
              className="bg-blue-600 hover:bg-blue-700"
            >
              <Plus className="w-4 h-4 mr-2" />
              Create Data Tap
            </Button>
          </div>
        )}
  
        {/* Loading State */}
        {isLoading && (
          <div className={`flex flex-col items-center justify-center py-12 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            <LoadingSpinner size="lg" className="mb-4" />
            <p className="text-sm">Loading market data taps...</p>
          </div>
        )}
  
        {/* Documentation Panel */}
        <div className={`mt-8 p-6 rounded-lg border ${
          isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
        }`}>
          <h3 className={`text-lg font-medium mb-4 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Understanding Data Taps
          </h3>
          <div className="grid md:grid-cols-3 gap-6">
            <div>
              <h4 className={`text-sm font-medium mb-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Frequencies
              </h4>
              <ul className={`text-sm space-y-2 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                <li className="flex items-center">
                  <Clock className="w-4 h-4 mr-2" />
                  1m - 1w intervals available
                </li>
                <li>Real-time updates for active taps</li>
                <li>Automatic data aggregation</li>
              </ul>
            </div>
            <div>
              <h4 className={`text-sm font-medium mb-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Metrics
              </h4>
              <ul className={`text-sm space-y-2 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                <li className="flex items-center">
                  <BarChart4 className="w-4 h-4 mr-2" />
                  OHLCV data available
                </li>
                <li>Custom metric calculations</li>
                <li>Volume analysis tools</li>
              </ul>
            </div>
            <div>
              <h4 className={`text-sm font-medium mb-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Features
              </h4>
              <ul className={`text-sm space-y-2 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                <li className="flex items-center">
                  <Settings className="w-4 h-4 mr-2" />
                  Automatic error recovery
                </li>
                <li>Data validation & cleaning</li>
                <li>Historical backfilling</li>
              </ul>
            </div>
          </div>
        </div>
  
        {/* Mini Performance Stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {[
            { label: 'Uptime', value: '99.9%', trend: 'up' },
            { label: 'Response Time', value: '45ms', trend: 'down' },
            { label: 'Success Rate', value: '99.7%', trend: 'up' },
            { label: 'Data Points/Day', value: '1.2M', trend: 'up' }
          ].map((stat, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg ${
                isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
              } border`}
            >
              <div className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {stat.label}
              </div>
              <div className="flex items-center mt-1">
                <span className={`text-xl font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {stat.value}
                </span>
                <div className={`ml-2 flex items-center text-sm ${
                  stat.trend === 'up' ? 'text-green-500' : 'text-red-500'
                }`}>
                  {stat.trend === 'up' ? '↑' : '↓'}
                  <span className="ml-1">2.1%</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default MarketData;
// src/components/widgets/WidgetSettings.tsx

import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

export interface WidgetSettingsProps {
  onSave?: (settings: any) => Promise<void>;
}

export const WidgetSettings: React.FC<WidgetSettingsProps> = ({ onSave }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg`}>
      <h2 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Widget Settings
      </h2>
      {/* Simplified settings UI */}
    </div>
  );
};

export default WidgetSettings;
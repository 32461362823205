// client/src/types/credits.ts
export interface CreditTransaction {
  id: string;
  amount: number;
  type: 'PURCHASE' | 'COUPON' | 'USAGE';
  couponCode?: string;
  status: 'PENDING' | 'COMPLETED' | 'FAILED';
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}
  
export interface CreditPackage {
  id: string;
  name: string;
  credits: number;
  price: number;
}
  
export const CREDIT_PACKAGES: CreditPackage[] = [
  { id: 'basic', name: 'Basic', credits: 100, price: 10 },
  { id: 'pro', name: 'Professional', credits: 500, price: 45 },
  { id: 'enterprise', name: 'Enterprise', credits: 2000, price: 160 },
];

  // types/credits.ts
  export interface CreditDeductionParams {
    cost: number;
    reason?: string;
  }
  
  
  // Credit calculation constants
  export const BASE_CREDIT_COST = 10;
  export const WORD_CREDIT_MULTIPLIER = 0.5;
  export const CHART_COMPLEXITY_MULTIPLIER = 1.5;
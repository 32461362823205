// src/types/widget.ts

export enum WidgetType {
  CHART = 'CHART',
  TABLE = 'TABLE',
  FORM = 'FORM',
  MONITOR = 'MONITOR'
}

// Visual settings for backward compatibility
export interface VisualConfig {
  theme?: {
    colors?: string[];
    fontFamily?: string;
    fontSize?: string;
  };
  layout?: {
    padding?: number;
    gap?: number;
  };
}

// Base widget interface
// Add these interfaces:
export interface Widget {
  id: string;
  name: string;
  description?: string;
  type: WidgetType;
  prompt?: string;
  code?: string;
  config?: Record<string, any>;
  content?: {
    code?: string;
    data?: any[];
  };
  createdAt: Date;
  updatedAt: Date;
}


export interface CreateWidgetInput {
  name: string;
  type: WidgetType;
  prompt: string;
  description?: string;
  config?: Record<string, any>;
}

export interface ChartAxisConfig {
  dataKey: string;
  label: string;
  tickRotation: number;
}
// Widget update input
export interface UpdateWidgetInput {
  name?: string;
  description?: string;
  prompt?: string;
  code?: string;
  config?: Record<string, any>;
}

// For existing code compatibility
export interface ChartWidgetConfig {
  type: WidgetType;
  config?: Record<string, any>;
}

export function isChartWidget(widget: Widget): boolean {
  return widget.type === WidgetType.CHART;
}

export function isTableWidget(widget: Widget): boolean {
  return widget.type === WidgetType.TABLE;
}

export function isFormWidget(widget: Widget): boolean {
  return widget.type === WidgetType.FORM;
}

export function isMonitorWidget(widget: Widget): boolean {
  return widget.type === WidgetType.MONITOR;
}

// Default configurations for backward compatibility
export const DEFAULT_WIDGET_CONFIG = {
  visual: {
    theme: {
      colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444'],
      fontFamily: 'Inter',
      fontSize: 'md'
    },
    layout: {
      padding: 4,
      gap: 4
    }
  }
};

export interface WidgetSettings {
  appearance: {
    theme: 'light' | 'dark' | 'system';
    density: 'comfortable' | 'compact' | 'expanded';
  };
  updates: {
    autoRefresh: boolean;
    refreshInterval: number;
  };
  notifications: {
    email: boolean;
    alerts: boolean;
  };
}
import React from 'react';
import { useTheme } from '../contexts/ThemeContext';

const OctagonPattern: React.FC = () => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {/* Large Gradient Background */}
      <div className={`absolute inset-0 ${
        isDarkMode 
          ? 'bg-gradient-to-br from-blue-900/20 to-purple-900/20' 
          : 'bg-gradient-to-br from-blue-100/50 to-purple-100/50'
      }`} />
      
      <svg
        className="absolute inset-0 w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <pattern
            id="octagon-pattern"
            x="0"
            y="0"
            width="50"
            height="50"
            patternUnits="userSpaceOnUse"
          >
            <path
              d={`M25,2 L33,10 L33,20 L25,28 L15,28 L7,20 L7,10 L15,2 Z`}
              fill="none"
              stroke={isDarkMode ? '#2d3748' : '#e2e8f0'}
              strokeWidth="0.5"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#octagon-pattern)" />
      </svg>
      
      {/* Header Protection Gradient */}
      <div className={`absolute top-0 left-0 w-full h-32 ${
        isDarkMode 
          ? 'bg-gradient-to-b from-gray-900 via-gray-900/45 to-transparent' 
          : 'bg-gradient-to-b from-gray-50 via-gray-50/45 to-transparent'
      }`} />
      
      {/* Bottom Gradient */}
      <div className={`absolute bottom-0 left-0 w-full h-64 ${
        isDarkMode 
          ? 'bg-gradient-to-t from-gray-900 to-transparent' 
          : 'bg-gradient-to-t from-gray-50 to-transparent'
      }`} />
    </div>
  );
};

export default OctagonPattern;
// src/pages/resources/events.tsx
import { Calendar as CalendarIcon, MapPin, Users, ExternalLink } from 'lucide-react';
import { MainLayout } from '../../components/layout/MainLayout';
import { Card, CardContent } from '../../components/ui/card';
import { useTheme } from '../../contexts/ThemeContext';
interface Event {
  id: string;
  title: string;
  description: string;
  date: string;
  time: string;
  location: string;
  type: 'Webinar' | 'Workshop' | 'Conference';
  capacity: number;
  registeredCount: number;
  speakers: Array<{
    name: string;
    role: string;
    avatar: string;
  }>;
}

const events: Event[] = [
  {
    id: '1',
    title: 'AI Trading Widget Masterclass',
    description: 'Learn advanced techniques for creating AI-powered trading widgets',
    date: 'March 25, 2024',
    time: '2:00 PM EST',
    location: 'Virtual',
    type: 'Webinar',
    capacity: 500,
    registeredCount: 342,
    speakers: [
      {
        name: 'Dr. Sarah Chen',
        role: 'AI Research Lead',
        avatar: '/team/sarah-chen.jpg'
      }
    ]
  },
  // Add more events...
];

const EventsPage = () => {
  const { isDarkMode } = useTheme();

  return (
   
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <CalendarIcon className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Upcoming Events
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Join our events to learn from experts and connect with the trading community
              </p>
            </div>
          </div>
        </div>

        {/* Events List */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="space-y-8">
            {events.map((event) => (
              <Card
                key={event.id}
                className={`group hover:shadow-lg transition-shadow ${
                  isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white'
                }`}
              >
                <CardContent className="p-6">
                  <div className="flex flex-col md:flex-row md:items-center justify-between">
                    <div className="flex-1">
                      <div className={`inline-flex px-3 py-1 rounded-full text-sm font-medium mb-4 ${
                        event.type === 'Webinar'
                          ? 'bg-blue-100 text-blue-700'
                          : event.type === 'Workshop'
                            ? 'bg-green-100 text-green-700'
                            : 'bg-purple-100 text-purple-700'
                      }`}>
                        {event.type}
                      </div>
                      
                      <h3 className={`text-xl font-bold mb-2 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {event.title}
                      </h3>
                      
                      <p className={`mb-4 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-600'
                      }`}>
                        {event.description}
                      </p>
                      
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <div className="flex items-center space-x-2">
                          <CalendarIcon className="w-5 h-5 text-blue-500" />
                          <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                            {event.date} at {event.time}
                          </span>
                        </div>
                        
                        <div className="flex items-center space-x-2">
                          <MapPin className="w-5 h-5 text-green-500" />
                          <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                            {event.location}
                          </span>
                        </div>
                        
                        <div className="flex items-center space-x-2">
                          <Users className="w-5 h-5 text-purple-500" />
                          <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                            {event.registeredCount}/{event.capacity} registered
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-6 md:mt-0 md:ml-6 flex flex-col items-center">
                      <button className="px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center space-x-2">
                        <span>Register Now</span>
                        <ExternalLink className="w-4 h-4" />
                      </button>
                      
                      <div className="mt-4 flex -space-x-2">
                        {event.speakers.map((speaker, index) => (
                          <div
                            key={index}
                            className="relative"
                            title={`${speaker.name} - ${speaker.role}`}
                          >
                            <img
                              src={speaker.avatar}
                              alt={speaker.name}
                              className="w-8 h-8 rounded-full border-2 border-white"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    
  );
};

export default EventsPage;
// src/hooks/useWidget.ts

import { useState, useCallback } from 'react';
import { Widget, CreateWidgetInput } from '../types/widget';
import { widgetService } from '../services/widgetService';

export function useWidget(widgetId?: string) {
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [widget, setWidget] = useState<Widget | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createWidget = async (input: CreateWidgetInput): Promise<Widget> => {
    setIsLoading(true);
    try {
      const result = await widgetService.createWidget(input);
      setWidgets(prev => [...prev, result]);
      return result;
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to create widget';
      setError(message);
      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateWidget = async (id: string, updates: Partial<Widget>): Promise<Widget> => {
    setIsLoading(true);
    try {
      const result = await widgetService.updateWidget(id, updates);
      setWidgets(prev => prev.map(w => w.id === id ? result : w));
      if (widget?.id === id) {
        setWidget(result);
      }
      return result;
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to update widget';
      setError(message);
      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWidget = async (id: string): Promise<void> => {
    setIsLoading(true);
    try {
      await widgetService.deleteWidget(id);
      setWidgets(prev => prev.filter(w => w.id !== id));
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to delete widget';
      setError(message);
      throw new Error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWidgets = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await widgetService.getUserWidgets();
      setWidgets(result);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to fetch widgets';
      setError(message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    widget,
    widgets,
    isLoading,
    error,
    createWidget,
    updateWidget,
    deleteWidget,
    refetch: fetchWidgets
  };
}
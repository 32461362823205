// src/pages/admin/BetaManagement.tsx
import React, { useState, useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { api } from '../../services/api';
import { 
  Users, 
  Mail, 
  Calendar, 
  CheckCircle2, 
  XCircle, 
  Clock, 
  Plus,
  Download,
  Search,
  Filter,
  RefreshCw,
  AlertTriangle
} from 'lucide-react';
import { LoadingSpinner } from '../../components/common/LoadingSpinner';
import { BetaRequestStatus } from '../../types/beta';

interface BetaRequest {
  id: string;
  email: string;
  status: BetaRequestStatus;
  createdAt: string;
  metadata?: {
    role?: string;
    company?: string;
    reason?: string;
  };
  code?: {
    code: string;
    expiresAt: string | null;
  };
}

interface BetaCode {
  id: string;
  code: string;
  email: string | null;
  isUsed: boolean;
  createdAt: string;
  expiresAt: string | null;
}

export const BetaManagement: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [requests, setRequests] = useState<BetaRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<BetaRequestStatus | 'ALL'>('ALL');
  const [search, setSearch] = useState('');
  const [isGeneratingCodes, setIsGeneratingCodes] = useState(false);
  const [showCodeGenerator, setShowCodeGenerator] = useState(false);
  const [codeGenCount, setCodeGenCount] = useState(1);
  const [codeExpireDays, setCodeExpireDays] = useState(7);

  useEffect(() => {
    fetchRequests();
  }, [filter]);

  const fetchRequests = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const params = new URLSearchParams();
      if (filter !== 'ALL') {
        params.append('status', filter);
      }
      const { data } = await api.get(`/beta/admin/requests?${params.toString()}`);
      setRequests(data.requests);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to fetch beta requests');
      console.error('Error fetching beta requests:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveRequest = async (id: string) => {
    try {
      await api.post(`/beta/admin/requests/${id}/approve`);
      await fetchRequests();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to approve request');
    }
  };

  const handleRejectRequest = async (id: string) => {
    try {
      await api.post(`/beta/admin/requests/${id}/reject`);
      await fetchRequests();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to reject request');
    }
  };

  const handleGenerateCodes = async () => {
    try {
      setIsGeneratingCodes(true);
      const { data } = await api.post('/beta/admin/codes/generate', {
        count: codeGenCount,
        expiresInDays: codeExpireDays
      });
      // Handle success - maybe download codes as CSV
      const csvContent = data.codes.map((code: BetaCode) => 
        `${code.code},${code.expiresAt || 'Never'}`
      ).join('\n');
      const blob = new Blob([`Code,Expires At\n${csvContent}`], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `beta-codes-${new Date().toISOString().split('T')[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setShowCodeGenerator(false);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to generate codes');
    } finally {
      setIsGeneratingCodes(false);
    }
  };

  const filteredRequests = requests.filter(request => {
    if (search) {
      return request.email.toLowerCase().includes(search.toLowerCase()) ||
             request.metadata?.company?.toLowerCase().includes(search.toLowerCase());
    }
    return true;
  });

  const getStatusColor = (status: BetaRequestStatus) => {
    switch (status) {
      case 'APPROVED':
        return 'text-green-500';
      case 'REJECTED':
        return 'text-red-500';
      case 'PENDING':
        return 'text-yellow-500';
      default:
        return 'text-gray-500';
    }
  };

  const getStatusIcon = (status: BetaRequestStatus) => {
    switch (status) {
      case 'APPROVED':
        return <CheckCircle2 className="w-5 h-5" />;
      case 'REJECTED':
        return <XCircle className="w-5 h-5" />;
      case 'PENDING':
        return <Clock className="w-5 h-5" />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className={`text-2xl font-bold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Beta Access Management
          </h1>
          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
            Manage beta access requests and generate invitation codes
          </p>
        </div>
        <button
          onClick={() => setShowCodeGenerator(true)}
          className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700"
        >
          <Plus className="w-5 h-5 mr-2" />
          Generate Codes
        </button>
      </div>

      {/* Error Alert */}
      {error && (
        <div className="mb-6 p-4 rounded-lg bg-red-50 border border-red-200 flex items-center">
          <AlertTriangle className="w-5 h-5 text-red-500 mr-2" />
          <span className="text-red-600">{error}</span>
        </div>
      )}

      {/* Filters */}
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search by email or company..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={`w-full pl-10 pr-4 py-2 rounded-lg ${
                isDarkMode 
                  ? 'bg-gray-800 text-white border-gray-700' 
                  : 'bg-white text-gray-900 border-gray-200'
              } border focus:ring-2 focus:ring-blue-500`}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value as BetaRequestStatus | 'ALL')}
            className={`px-4 py-2 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-800 text-white border-gray-700' 
                : 'bg-white text-gray-900 border-gray-200'
            } border focus:ring-2 focus:ring-blue-500`}
          >
            <option value="ALL">All Requests</option>
            <option value="PENDING">Pending</option>
            <option value="APPROVED">Approved</option>
            <option value="REJECTED">Rejected</option>
          </select>
          <button
            onClick={fetchRequests}
            className={`p-2 rounded-lg ${
              isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
            }`}
          >
            <RefreshCw className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Requests Table */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner size="lg" />
        </div>
      ) : filteredRequests.length === 0 ? (
        <div className={`text-center py-12 rounded-lg border-2 border-dashed ${
          isDarkMode ? 'border-gray-700' : 'border-gray-200'
        }`}>
          <Users className={`w-12 h-12 mx-auto mb-4 ${
            isDarkMode ? 'text-gray-600' : 'text-gray-400'
          }`} />
          <h3 className={`text-lg font-medium mb-2 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            No requests found
          </h3>
          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
            {search ? 'Try adjusting your search' : 'No beta access requests yet'}
          </p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className={`w-full ${
            isDarkMode ? 'text-gray-200' : 'text-gray-900'
          }`}>
            <thead>
              <tr className={isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Company
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredRequests.map((request) => (
                <tr key={request.id} className={isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <Mail className="w-5 h-5 mr-2 text-gray-400" />
                      {request.email}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {request.metadata?.company || '-'}
                  </td>
                  <td className="px-6 py-4">
                    {request.metadata?.role || '-'}
                  </td>
                  <td className="px-6 py-4">
                    <div className={`flex items-center ${getStatusColor(request.status)}`}>
                      {getStatusIcon(request.status)}
                      <span className="ml-2">{request.status}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {new Date(request.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-right">
                    {request.status === 'PENDING' && (
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => handleApproveRequest(request.id)}
                          className="px-3 py-1 rounded-lg bg-green-100 text-green-600 hover:bg-green-200"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleRejectRequest(request.id)}
                          className="px-3 py-1 rounded-lg bg-red-100 text-red-600 hover:bg-red-200"
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Code Generator Modal */}
      {showCodeGenerator && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className={`w-full max-w-md rounded-xl p-6 ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h3 className={`text-xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Generate Beta Codes
            </h3>
            <div className="space-y-4">
              <div>
                <label className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-700'
                }`}>
                  Number of Codes
                </label>
                <input
                  type="number"
                  value={codeGenCount}
                  onChange={(e) => setCodeGenCount(Math.max(1, parseInt(e.target.value) || 1))}
                  min="1"
                  className={`w-full px-3 py-2 rounded-lg ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white border-gray-600' 
                      : 'bg-white text-gray-900 border-gray-300'
                  } border focus:ring-2 focus:ring-blue-500`}
                />
              </div>
              <div>
                <label className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-200' : 'text-gray-700'
                }`}>
                  Expire After (Days)
                </label>
                <input
                 type="number"
                 value={codeExpireDays}
                 onChange={(e) => setCodeExpireDays(Math.max(0, parseInt(e.target.value) || 0))}
                 min="0"
                 placeholder="0 for no expiration"
                 className={`w-full px-3 py-2 rounded-lg ${
                   isDarkMode 
                     ? 'bg-gray-700 text-white border-gray-600' 
                     : 'bg-white text-gray-900 border-gray-300'
                 } border focus:ring-2 focus:ring-blue-500`}
               />
             </div>
             <div className="flex justify-end gap-3 mt-6">
               <button
                 onClick={() => setShowCodeGenerator(false)}
                 className={`px-4 py-2 rounded-lg ${
                   isDarkMode 
                     ? 'bg-gray-700 text-white hover:bg-gray-600' 
                     : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                 }`}
               >
                 Cancel
               </button>
               <button
                 onClick={handleGenerateCodes}
                 disabled={isGeneratingCodes}
                 className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
               >
                 {isGeneratingCodes ? (
                   <>
                     <LoadingSpinner size="sm" className="mr-2" />
                     Generating...
                   </>
                 ) : (
                   <>
                     <Download className="w-4 h-4 mr-2" />
                     Generate & Download
                   </>
                 )}
               </button>
             </div>
           </div>
         </div>
       </div>
     )}
   </div>
 );
};

export default BetaManagement;
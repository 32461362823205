// src/pages/legal/security.tsx
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { MainLayout } from '../../components/layout/MainLayout';
import { 
  Shield, 
  Lock, 
  Key, 
  RefreshCw, 
  Server, 
  AlertTriangle,
  ChevronRight 
} from 'lucide-react';

interface SecuritySection {
  title: string;
  icon: React.FC<{ className?: string }>;
  content: string;
  items?: string[];
}

const securitySections: SecuritySection[] = [
  {
    title: 'Data Encryption',
    icon: Lock,
    content: 'All data transmitted between your browser and our servers is encrypted using industry-standard protocols.',
    items: [
      'TLS 1.3 encryption for all data in transit',
      'AES-256 encryption for stored data',
      'End-to-end encryption for sensitive information',
      'Regular security certificate updates'
    ]
  },
  {
    title: 'Access Control',
    icon: Key,
    content: 'We implement strict access controls to protect your account and data.',
    items: [
      'Multi-factor authentication support',
      'Role-based access control (RBAC)',
      'Session management and automatic timeouts',
      'IP-based access restrictions available',
      'Detailed access logs and monitoring'
    ]
  },
  {
    title: 'Infrastructure Security',
    icon: Server,
    content: 'Our infrastructure is built with security as a top priority.',
    items: [
      'Regular security audits and penetration testing',
      'DDoS protection and mitigation',
      'Redundant backup systems',
      'Real-time threat monitoring',
      'Secure data centers with physical security'
    ]
  },
  {
    title: 'Incident Response',
    icon: AlertTriangle,
    content: 'We maintain a comprehensive incident response plan.',
    items: [
      '24/7 security monitoring',
      'Automated threat detection',
      'Incident response team on standby',
      'Regular security drills and updates',
      'Transparent incident reporting'
    ]
  },
  {
    title: 'Continuous Updates',
    icon: RefreshCw,
    content: 'We regularly update our security measures to address new threats.',
    items: [
      'Regular security patches and updates',
      'Vulnerability scanning and remediation',
      'Third-party security audits',
      'Compliance with industry standards',
      'Regular employee security training'
    ]
  }
];

const SecurityPage = () => {
  const { isDarkMode } = useTheme();

  return (
   
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Shield className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Security
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Learn about our comprehensive security measures protecting your data and trades
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Security Overview */}
          <div className={`mb-16 p-8 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Our Security Commitment
            </h2>
            <p className={`text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              At Newsroom, security is not just a feature - it's a fundamental part of our platform.
              We employ multiple layers of security measures to protect your data, trades, and personal information.
            </p>
          </div>

          {/* Security Sections */}
          <div className="grid md:grid-cols-2 gap-8">
            {securitySections.map((section, index) => {
              const Icon = section.icon;
              return (
                <div
                  key={index}
                  className={`p-6 rounded-lg ${
                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                  }`}
                >
                  <div className="flex items-center mb-4">
                    <div className="p-3 rounded-lg bg-blue-600">
                      <Icon className="w-6 h-6 text-white" />
                    </div>
                    <h3 className={`text-xl font-bold ml-4 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {section.title}
                    </h3>
                  </div>
                  <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {section.content}
                  </p>
                  {section.items && (
                    <ul className="space-y-2">
                      {section.items.map((item, i) => (
                        <li
                          key={i}
                          className={`flex items-start ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-600'
                          }`}
                        >
                          <ChevronRight className="w-5 h-5 mr-2 flex-shrink-0 text-blue-500" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>

          {/* Report Security Issues */}
          <div className={`mt-16 p-8 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h2 className={`text-2xl font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Report Security Issues
            </h2>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              We take security seriously and appreciate your help in keeping Newsroom secure.
              If you discover a security vulnerability, please report it to our security team:
            </p>
            <a
              href="mailto:security@news-room.ca"
              className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              <AlertTriangle className="w-5 h-5 mr-2" />
              Report Vulnerability
            </a>
          </div>
        </div>
      </div>
   
  );
};

export default SecurityPage;
// src/pages/legal/cookies.tsx
import { Cookie, Info, Settings } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

interface CookieType {
  name: string;
  purpose: string;
  duration: string;
  required: boolean;
}

const cookieTypes: Record<string, CookieType[]> = {
  'Essential': [
    {
      name: 'session_id',
      purpose: 'Maintains your session state',
      duration: 'Session',
      required: true
    },
    {
      name: 'csrf_token',
      purpose: 'Prevents cross-site request forgery',
      duration: 'Session',
      required: true
    }
  ],
  'Functional': [
    {
      name: 'theme_preference',
      purpose: 'Stores your theme preferences',
      duration: '1 year',
      required: false
    },
    {
      name: 'widget_settings',
      purpose: 'Saves your widget customizations',
      duration: '6 months',
      required: false
    }
  ],
  'Analytics': [
    {
      name: 'analytics_id',
      purpose: 'Tracks anonymous usage patterns',
      duration: '2 years',
      required: false
    },
    {
      name: 'performance_data',
      purpose: 'Monitors site performance',
      duration: '30 days',
      required: false
    }
  ]
};

const CookiesPage = () => {
  const { isDarkMode } = useTheme();

  return (
    
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Cookie className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Cookie Policy
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Understanding how we use cookies to improve your experience
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`mb-12 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            <p className="mb-4">
              This Cookie Policy explains how Newsroom uses cookies and similar tracking technologies
              on our website and platform. By using our services, you consent to our use of cookies
              in accordance with this policy.
            </p>
            <p>
              You can manage your cookie preferences at any time through your browser settings or
              our cookie management tool.
            </p>
          </div>

          {/* Cookie Types */}
          {Object.entries(cookieTypes).map(([category, cookies]) => (
            <div
              key={category}
              className={`mb-12 p-6 rounded-lg ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <h2 className={`text-xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {category} Cookies
                {category === 'Essential' && (
                  <span className="ml-2 text-sm text-blue-500">(Required)</span>
                )}
              </h2>
              <div className="space-y-6">
                {cookies.map((cookie, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <h3 className={`font-medium ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {cookie.name}
                      </h3>
                      <span className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Duration: {cookie.duration}
                      </span>
                    </div>
                    <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                      {cookie.purpose}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* Cookie Management */}
          <div className={`p-6 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <div className="flex items-center mb-4">
              <Settings className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Managing Your Cookie Preferences
              </h2>
            </div>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              You can manage or delete cookies based on your preferences. You can delete all cookies
              that are already on your device and you can set most browsers to prevent them from
              being placed.
            </p>
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              onClick={() => {
                // Open cookie preferences modal
              }}
            >
              Update Cookie Preferences
            </button>
          </div>

          {/* Additional Information */}
          <div className={`mt-12 p-6 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <div className="flex items-center mb-4">
              <Info className="w-6 h-6 text-blue-500 mr-2" />
              <h2 className={`text-xl font-bold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Additional Information
              </h2>
            </div>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              For any questions about our use of cookies, please contact our privacy team:
            </p>
            <a
              href="mailto:privacy@news-room.ca"
              className="text-blue-500 hover:text-blue-600"
            >
              privacy@news-room.ca
            </a>
          </div>
        </div>
      </div>
    
  );
};

export default CookiesPage;
// src/config/seo.ts
export const siteConfig = {
    title: "Newsroom - AI Stock Widget Generator",
    titleTemplate: "%s | Newsroom",
    description: "Create custom AI-powered trading widgets instantly. Real-time market data visualization and technical analysis tools.",
    siteUrl: "https://news-room.ca",
    twitterHandle: "@newsroomHQ",
    language: "en",
    author: {
      name: "Newsroom",
      url: "https://news-room.ca",
    },
    organization: {
      name: "Newsroom",
      url: "https://news-room.ca",
      logo: "https://news-room.ca/logo.png",
    }
  };
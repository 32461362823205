// src/components/widgets/DynamicWidgetRenderer.tsx
import React from 'react';
import { ErrorBoundary } from '../common/ErrorBoundary';
import { Alert } from '../ui/alert';
import { AlertTriangle } from 'lucide-react';
import * as recharts from 'recharts';
import * as lucide from 'lucide-react';

interface DynamicWidgetRendererProps {
  code: string;
  className?: string;
  data?: any;
}

export const DynamicWidgetRenderer: React.FC<DynamicWidgetRendererProps> = ({
  code,
  className = '',
  data
}) => {
  try {
    // Clean up code by removing dependency declarations since we inject them
    const cleanCode = code;

    // Create component with access to dependencies
    const createComponent = new Function(
      'React',
      'recharts',
      'lucide',
      `
        with (recharts) {
          with (lucide) {
            ${cleanCode}
          }
        }
      `
    );

    const Component = createComponent(React, recharts, lucide);

    if (!Component) {
      throw new Error('No component exported');
    }

    return (
      <ErrorBoundary>
        <div className={className}>
          <Component data={data} loading={false} />
        </div>
      </ErrorBoundary>
    );
  } catch (error) {
    console.error('Widget rendering error:', error);
    return (
      <Alert variant="destructive">
        <AlertTriangle className="h-4 w-4" />
        <span>Failed to render widget: {error instanceof Error ? error.message : 'Unknown error'}</span>
      </Alert>
    );
  }
};

export default DynamicWidgetRenderer;
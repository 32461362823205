// src/pages/legal/terms.tsx
import { Scale } from 'lucide-react';
import { ChevronRight } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const termsData = [
  {
    title: 'Acceptance of Terms',
    content: `By accessing and using Newsroom's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.`
  },
  {
    title: 'Service Description',
    content: `Newsroom provides AI-powered trading widget generation and customization services. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time.`
  },
  {
    title: 'User Accounts',
    content: `• You must be at least 18 years old to use our services
• You are responsible for maintaining the confidentiality of your account
• You agree to provide accurate and complete information
• You are responsible for all activities under your account`
  },
  {
    title: 'Payment Terms',
    content: `• Payment is required for certain features and services
• All fees are non-refundable unless otherwise stated
• We may modify pricing with 30 days notice
• Credits expire after 12 months of inactivity`
  },
  {
    title: 'Intellectual Property',
    content: `• You retain ownership of your data
• We own all rights to our platform and technology
• You grant us license to use your content to provide services
• You may not copy or reverse engineer our technology`
  },
  {
    title: 'Limitation of Liability',
    content: `• Services are provided "as is" without warranty
• We are not liable for trading decisions or losses
• Maximum liability limited to fees paid in last 12 months
• Some jurisdictions may not allow liability limitations`
  }
];

const TermsOfService = () => {
  const { isDarkMode } = useTheme();

  return (
    
      <div className={isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}>
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/10 to-purple-600/10" />
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-16 relative">
            <div className="text-center">
              <Scale className={`w-12 h-12 mx-auto mb-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
              <h1 className={`text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Terms of Service
              </h1>
              <p className={`text-xl max-w-3xl mx-auto ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                Last updated: March 15, 2024
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Introduction */}
          <div className={`mb-12 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            <p>
              Please read these Terms of Service ("Terms", "Terms of Service") carefully before using
              the Newsroom platform and services. These terms constitute a legally binding agreement
              between you and Newsroom.
            </p>
          </div>

          {/* Quick Navigation */}
          <div className={`mb-12 p-6 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h2 className={`text-lg font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Table of Contents
            </h2>
            <div className="grid gap-2">
              {termsData.map((section, index) => (
                <a
                  key={index}
                  href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className={`flex items-center justify-between p-3 rounded-lg transition-colors ${
                    isDarkMode
                      ? 'hover:bg-gray-700 text-gray-300'
                      : 'hover:bg-gray-50 text-gray-600'
                  }`}
                >
                  <span>{section.title}</span>
                  <ChevronRight className="w-4 h-4" />
                </a>
              ))}
            </div>
          </div>

          {/* Sections */}
          <div className="space-y-12">
            {termsData.map((section, index) => (
              <section
                key={index}
                id={section.title.toLowerCase().replace(/\s+/g, '-')}
                className={`scroll-mt-24 ${
                  index !== termsData.length - 1 ? 'pb-12 border-b' : ''
                } ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
              >
                <h2 className={`text-2xl font-bold mb-6 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {section.title}
                </h2>
                <div className={`prose max-w-none ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {section.content.split('\n').map((paragraph, i) => (
                    <p key={i} className="whitespace-pre-line mb-4">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </section>
            ))}
          </div>

          {/* Contact Information */}
          <div className={`mt-16 p-6 rounded-lg ${
            isDarkMode ? 'bg-gray-800' : 'bg-white'
          }`}>
            <h2 className={`text-lg font-bold mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Contact Us
            </h2>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              If you have any questions about these Terms, please contact our legal team at:
            </p>
            <a
              href="mailto:legal@news-room.ca"
              className="text-blue-500 hover:text-blue-600"
            >
              legal@news-room.ca
            </a>
          </div>
        </div>
      </div>
    
  );
};

export default TermsOfService;
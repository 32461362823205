// src/services/creditService.ts
import api from './api';
import { 
  CreditTransaction,
  CreditDeductionParams,
  CreditPackage
} from '../types/credits';
import { WidgetType } from '../types/widget';

const COST_MULTIPLIERS = {
  [WidgetType.CHART]: 1.5,
  [WidgetType.TABLE]: 1.2,
  [WidgetType.FORM]: 1.0,
  [WidgetType.MONITOR]: 1.3
};

const BASE_CREDIT_COST = 10;
const WORD_CREDIT_MULTIPLIER = 0.5;

export class CreditService {
  private static instance: CreditService;
  
  private constructor() {}

  static getInstance(): CreditService {
    if (!CreditService.instance) {
      CreditService.instance = new CreditService();
    }
    return CreditService.instance;
  }

  async deductCredits(params: CreditDeductionParams): Promise<CreditTransaction> {
    try {
      const { data } = await api.post<CreditTransaction>('/credits/deduct', params);
      return data;
    } catch (error) {
      console.error('Credit deduction error:', error);
      throw error;
    }
  }

  calculateWidgetCost(description: string, type: WidgetType): number {
    // Base cost
    let cost = BASE_CREDIT_COST;

    // Add cost based on description length
    const wordCount = description.split(' ').length;
    cost += Math.ceil(wordCount * WORD_CREDIT_MULTIPLIER);

    // Apply widget type multiplier
    cost = Math.ceil(cost * (COST_MULTIPLIERS[type] || 1));

    return cost;
  }

  async getCurrentCredits(): Promise<number> {
    const { data } = await api.get<{ credits: number }>('/credits');
    return data.credits;
  }

  async purchaseCredits(packageId: string): Promise<CreditTransaction> {
    const { data } = await api.post<CreditTransaction>('/credits/purchase', {
      packageId
    });
    return data;
  }

  async redeemCoupon(couponCode: string): Promise<CreditTransaction> {
    const { data } = await api.post<CreditTransaction>('/credits/redeem', {
      couponCode
    });
    return data;
  }

  async getTransactionHistory(): Promise<CreditTransaction[]> {
    const { data } = await api.get<CreditTransaction[]>('/credits/transactions');
    return data;
  }

  getCreditPackages(): CreditPackage[] {
    return [
      { id: 'basic', name: 'Basic', credits: 100, price: 10 },
      { id: 'pro', name: 'Professional', credits: 500, price: 45 },
      { id: 'enterprise', name: 'Enterprise', credits: 2000, price: 160 }
    ];
  }
}

export const creditService = CreditService.getInstance();
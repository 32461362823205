// src/components/widgets/WidgetCustomizationPanel.tsx

import React, { useState } from 'react';
import { Widget } from '../../types/widget';
import { useTheme } from '../../contexts/ThemeContext';
import { AlertTriangle, Save } from 'lucide-react';
import { Alert } from '../ui/alert';
import { AIMessage } from '../../types/shared';
interface WidgetCustomizationPanelProps {
    widget: Widget;
    onSave: (config: any) => Promise<void>;
    onAIRefine?: (instruction: string, context: AIMessage[]) => Promise<string>;
    className?: string;
  }

export const WidgetCustomizationPanel: React.FC<WidgetCustomizationPanelProps> = ({
  widget,
  onSave,
  className = ''
}) => {
  const { isDarkMode } = useTheme();
  const [config, setConfig] = useState(widget.config || {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await onSave(config);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save changes');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`space-y-6 ${className}`}>
      <div className="flex justify-between items-center">
        <h2 className={`text-2xl font-bold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Customize Widget
        </h2>
        <button
          onClick={handleSave}
          disabled={isLoading}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center"
        >
          <Save className="w-4 h-4 mr-2" />
          {isLoading ? 'Saving...' : 'Save Changes'}
        </button>
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertTriangle className="w-4 h-4 mr-2" />
          {error}
        </Alert>
      )}

      {/* Customization form will go here */}
    </div>
  );
};

export default WidgetCustomizationPanel;